import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";

function RdDepartment({ state, handleStateChange }) {
  const handleInputChange = (event) => {
    handleStateChange(event.target.name, event.target.value);
  };

  return (
    <FormControl fullWidth size="small">
      <InputLabel id="demo-simple-select-label">Job functions*</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={state.department}
        name="department"
        label="Job functions*"
        onChange={handleInputChange}
      >
        {positions.sort().map((position) => (
          <MenuItem key={position} value={position}>
            {position}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
const positions = [
  "Business Management",
  "Distributor/Channel Partner/Agent",
  "Engineer/Controller/Technician",
  "Finance/Financial Management",
  "Human Resources/Administration",
  "IT",
  "Logistics/Material Management/Warehousing & Transportation",
  "Management",
  "Operations",
  "Operator/Supervisor/Machinist",
  "Procurement",
  "Production/Manufacturing",
  "Programmer/Analyst/Planner",
  "Quality",
  "R&D/Design",
  "Safety",
  "Sales/Marketing/Servicing",
  "Training",
];
export default RdDepartment;
