import React from "react";
import "./ExhibitorCard.css";
import { Button, IconButton } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import StarIcon from "@mui/icons-material/Star";
import { Link } from "react-router-dom";
import ForumIcon from "@mui/icons-material/Forum";
import RoomIcon from "@mui/icons-material/Room";
import EastIcon from "@mui/icons-material/East";
import { DEFAULT_EX_ICON } from "../constants/constants";
import { getUser } from "../utility/utility";
const ExhibitorCard = ({ item }) => {
  return (
    <div className="exhibitor-card-c">
      <img
        src={item.profile_image ? item.profile_image : DEFAULT_EX_ICON}
        className="ex-img-left"
      />
      <div>
        <div className="name-two-lines">{item?.companyName?.value}</div>

        <Link to={`/hall-view/${item?.hallNumber?.value}`}>
          <Button
            startIcon={<RoomIcon color="#444" />}
            sx={{ textTransform: "none", color: "#444" }}
            variant="text"
          >
            {item?.hallNumber?.value} | {item?.standNumber?.value}
          </Button>
        </Link>
        <div style={{ display: "flex", margin: "4px 0" }}>
          <div style={{ flex: 1, display: "flex", gap: "12px" }}>
            <Link to={`/${getUser()?.role}/chat?tid=${item.username}`}>
              <IconButton
                sx={{ backgroundColor: "#dbedff" }}
                aria-label="message"
                size="small"
              >
                <ForumIcon sx={{ color: "#00a2d9" }} fontSize="inherit" />
              </IconButton>
            </Link>
            <Link
              to={`/${getUser()?.role}/meeting-scheduler?tid=${item.username}`}
            >
              <IconButton
                sx={{ backgroundColor: "#ffead2" }}
                aria-label="message"
                size="small"
              >
                <CalendarMonthIcon
                  sx={{ color: "#ff981f" }}
                  fontSize="inherit"
                />
              </IconButton>
            </Link>
            <Link to={`/${getUser()?.role}/favorites?id=${item.user_id}`}>
              <IconButton
                sx={{ backgroundColor: "#ffead2" }}
                aria-label="message"
                size="small"
              >
                <StarIcon sx={{ color: "#fb4d24" }} fontSize="inherit" />
              </IconButton>
            </Link>
          </div>
          <Link to={`/${getUser()?.role}/view-profile?id=${item.username}`}>
            <IconButton aria-label="arrow" size="small">
              <EastIcon fontSize="inherit" sx={{ color: "#3cb4e0" }} />
            </IconButton>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ExhibitorCard;
