import { IconButton } from "@mui/material";
import React from "react";

import ProductItemAction from "./actionBtn/productItemAction";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import StarIcon from "@mui/icons-material/Star";
import ForumIcon from "@mui/icons-material/Forum";
import EastIcon from "@mui/icons-material/East";
import { DEFAULT_EX_ICON } from "../constants/constants";
import defImg from "../assets/default-product-image.png";
import { Link } from "react-router-dom";
import { getUser } from "../utility/utility";

const ProductItem = ({
  product,
  onEditProduct,
  onDeleteProduct,
  viewMode = false,
}) => {
  return (
    <div className="product-item">
      <div className="product-image-cont">
        <img src={product.images ? product.images[0] : defImg} alt="" />
      </div>
      <div className="product-item-name">{product.name}</div>
      {viewMode ? (
        <div style={{ margin: "8px" }}>
          <div style={{ display: "flex" }}>
            <img
              style={{ width: "36px", height: "36px", borderRadius: "8px" }}
              src={
                product.exhibitor?.profile_image
                  ? product.exhibitor?.profile_image
                  : DEFAULT_EX_ICON
              }
            />
            <div
              style={{
                flex: 1,
                marginLeft: "10px",
                alignSelf: "center",
                fontWeight: 500,
                color: "#444",
                fontSize: "14px",
                whiteSpace: "nowrap", // Keeps the text in a single line
                overflow: "hidden", // Ensures the content is clipped
                textOverflow: "ellipsis", // Adds an ellipsis to clipped content
              }}
            >
              {product?.exhibitor?.companyShortName?.value}
            </div>
          </div>

          <div style={{ display: "flex", margin: "4px 0" }}>
            <div style={{ flex: 1, display: "flex", gap: "12px" }}>
              <Link
                to={`/${getUser()?.role}/chat?tid=${
                  product?.exhibitor?.username
                }`}
              >
                <IconButton
                  sx={{ backgroundColor: "#dbedff" }}
                  aria-label="message"
                  size="small"
                >
                  <ForumIcon sx={{ color: "#00a2d9" }} fontSize="inherit" />
                </IconButton>
              </Link>
              <Link
                to={`/${getUser()?.role}/meeting-scheduler?tid=${
                  product?.exhibitor?.username
                }`}
              >
                <IconButton
                  sx={{ backgroundColor: "#ffead2" }}
                  aria-label="message"
                  size="small"
                >
                  <CalendarMonthIcon
                    sx={{ color: "#ff981f" }}
                    fontSize="inherit"
                  />
                </IconButton>
              </Link>
              <IconButton
                sx={{ backgroundColor: "#ffead2" }}
                aria-label="message"
                size="small"
              >
                <StarIcon sx={{ color: "#fb4d24" }} fontSize="inherit" />
              </IconButton>
            </div>
            <Link to={`/${getUser()?.role}/product-view?id=${product?._id}`}>
              <IconButton aria-label="arrow" size="small">
                <EastIcon fontSize="inherit" sx={{ color: "#3cb4e0" }} />
              </IconButton>
            </Link>
          </div>
        </div>
      ) : (
        <ProductItemAction
          onEdit={() => onEditProduct(product)}
          onDelete={() => onDeleteProduct(product)}
        />
      )}
    </div>
  );
};

export default ProductItem;
