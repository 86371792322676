import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  Grid,
  ListItemButton,
  Alert,
  LinearProgress,
} from "@mui/material";
import CommonHeader from "../../header/CommonHeader";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { darken, lighten } from "@mui/system";
import { OES_COMPONENTS, USER } from "../../../../constants/constants";
import NextPrev from "./reuse/NextPrev";
import useExhibitorProfile from "../hooks/useExhibitorProfile";
import MuPb from "../../../../widgets/MuPb";
import useOesSubmitted from "./reuse/useOesSubmitted";
import { getDateFormat } from "../../../../utility/utility";
import useCommonGetInit from "../../../events/hooks/useCommonGetInit";

export function SubmittedStatus({ username, title }) {
  const { data, isLoading, isError, error } = useOesSubmitted(username, title);

  if (isLoading) {
    return <LinearProgress />;
  }
  if (isError) {
    return <></>;
  }

  return (
    <Box mt={2} mb={2}>
      <Alert severity="success">
        Submitted on {getDateFormat(new Date(data.updatedOn))}
      </Alert>
    </Box>
  );
}
export const oesMenus = [
  { title: "Exhibitor Manual", deadline: "24/05/2024" },
  { title: "Setup and Dismantling Schedule", deadline: "24/05/2024" },
  { title: "Utility Details (Power, Air, Water)", deadline: "24/05/2024" },
  { title: "4.1 Stand Possession", deadline: "24/05/2024" },
  { title: "4.2 Invitation for Inauguration", deadline: "24/05/2024" },
  { title: "4.3 Exhibitor Badges", deadline: "28/05/2024" },
  // { title: "4.3 A Service Badges", deadline: "05/01/2024" },
  { title: "4.4 Electrical Work Completion Report", deadline: "24/05/2024" },
  { title: "5.1 Site Handling", deadline: "20/05/2024" },
  { title: "5.2 Audio/Visual Equipment", deadline: "24/05/2024" },
  // { title: "4.5 Exit Pass", deadline: "24/01/2024" },
  // {
  //   title: "4.6 Guidelines For Building Mezzanine Floor",
  //   deadline: "17/01/2024",
  // },
  // { title: "5.1 Site Handling", deadline: "10/05/2024" },
  // { title: "5.2 Security", deadline: "10/05/2024" },
  // { title: "5.3 Hotel Accommodation", deadline: "10/05/2024" },
  // { title: "5.5 Seminar Halls", deadline: "10/05/2024" },
  // { title: "5.6 Audio/Visual Equipment", deadline: "10/05/2024" },
  // { title: "5.7 Temporary Personnel", deadline: "10/05/2024" },
  // { title: "5.8 Interpreters & Translators", deadline: "10/05/2024" },
  // { title: "5.9 Horticulture / Floriculture", deadline: "10/05/2024" },
  // {
  //   title: "6.1 Advertisement in Exhibition Catalogue",
  //   deadline: "10/05/2024",
  // },
  // { title: "6.2 Advertisement Show Daily", deadline: "10/05/2024" },
  // { title: "Visa Application", deadline: "10/05/2024" },
  // { title: "Additional Services", deadline: "10/05/2024" },
  // { title: "Eco Design Theme for Stands" },
  // { title: "Mandatory Design Brief for the Stand" },
  // { title: "Mandatory Design Note" },
  // { title: "Mandatory FORM A (to be completed by Exhibitor)" },
  // { title: "Mandatory FORM B (primary stand contractor)" },
  // { title: "Summary" },
];

const Services = () => {
  const theme = useTheme();
  const { data, isLoading, isError, error } = useCommonGetInit(
    `${USER}/get-user?`
  );
  const { id } = useParams();
  const LoadedService = OES_COMPONENTS[parseInt(id)];
  if (isLoading) {
    return <MuPb />;
  }
  if (isError) {
    return <Alert severity="warning">{error.message}</Alert>;
  }
  return <Alert severity="info">Form closed!</Alert>;

  return (
    <div>
      <CommonHeader user={data} />
      <Grid
        maxWidth={1320}
        sx={{ ml: "auto", mr: "auto" }}
        p={1}
        container
        spacing={1}
      >
        <Grid item xs={3.5}>
          <Box
            sx={{
              border: "1px solid #e0e0e0",
              borderRadius: 1,
              padding: "12px",
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                marginBottom: "4px",
                marginTop: "4px",
              }}
            >
              ONLINE EXHIBITOR SERVICES
            </div>
            <List>
              {oesMenus.map((menu, index) => {
                const isSelected = parseInt(id) === index;
                return (
                  <ListItemButton
                    key={index}
                    component="a"
                    href={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      overflow: "hidden",
                      backgroundColor: isSelected
                        ? "#054054" // Base color
                        : "transparent",
                      "&:hover": {
                        backgroundColor: isSelected
                          ? theme.palette.mode === "dark"
                            ? "#036767" // Slightly lighter color for dark mode hover
                            : "#032a2a" // Slightly darker color for light mode hover
                          : theme.palette.mode === "dark"
                          ? "rgba(255, 255, 255, 0.1)"
                          : "rgba(0, 0, 0, 0.04)",
                        color: theme.palette.text.primary,
                      },
                    }}
                  >
                    <Box
                      sx={{
                        minWidth: "28px",
                        minHeight: "28px",
                        width: "28px",
                        height: "28px",
                        borderRadius: "50% !important",
                        backgroundColor: isSelected
                          ? "#0c8c8c" // Lighter version of the base color
                          : "#0c8c8c", // Base color
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: "16px",
                        fontSize: "0.875rem",
                        fontWeight: "bold",
                      }}
                    >
                      {index + 1}
                    </Box>
                    <ListItemText
                      primary={menu.title}
                      secondary={`Deadline ${menu.deadline}`}
                      primaryTypographyProps={{
                        noWrap: true,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        flex: 1,
                        color: isSelected ? "white" : "text.secondary",
                      }}
                      secondaryTypographyProps={{
                        noWrap: true,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        color: isSelected ? "white" : "text.secondary",
                      }}
                    />
                  </ListItemButton>
                );
              })}
            </List>
          </Box>
        </Grid>
        <Grid item xs={8.5}>
          <LoadedService />
          <NextPrev page={parseInt(id)} totalItems={oesMenus.length} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Services;
