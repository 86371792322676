import { TextField } from "@mui/material";
import React, { useState } from "react";
import { getHeader } from "../../../../../utility/utility";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import axios from "axios";
import { VISITOR_API } from "../../../../../constants/constants";
import CitiesCom from "../../../../../visitorregt/components/previewpage/CitiesCom";
import StatesCom from "../../../../../visitorregt/components/previewpage/StatesCom";
import CountriesCom from "../../../../../visitorregt/components/previewpage/CountriesCom";
import { PROFILE_EDIT_API } from "../../ProfileDetails";

const CompanyInfoEdit = ({ profile, setProfile, setShow }) => {
  const [saving, setSaving] = useState(false);
  const handleInputChange = (e) => {
    setProfile((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const handleStateChange = (key, value) => {
    setProfile((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const save = () => {
    const checks = [
      { attr: "companyName", message: "Please enter Company Name" },
      { attr: "address", message: "Please enter Address" },
      { attr: "selectedCountry", message: "Please select country" },
      { attr: "selectedState", message: "Please select State" },
      { attr: "selectedCity", message: "Please select City" },
      { attr: "pin", message: "Please enter Pin" },
    ];

    for (let check of checks) {
      const value = profile[check.attr];
      if (
        (check.validator && !check.validator(value)) ||
        value === "" ||
        value === undefined
      ) {
        toast.error(check.message);
        return;
      }
    }
    setSaving(true);
    const dataToUpload = {
      companyName: profile.companyName,
      address: profile.address,
      selectedCountry: profile.selectedCountry,
      selectedState: profile.selectedState,
      selectedCity: profile.selectedCity,
      pin: profile.pin,
      website: profile.website,
      telephone: profile.telephone,
    };
    axios
      .post(PROFILE_EDIT_API, dataToUpload, getHeader())
      .then((res) => {
        setSaving(false);
        toast.success(res.data.message);
        setShow((prev) => !prev);
      })
      .catch((error) => {
        setSaving(false);
        if (error.response) {
          // The request was made and the server responded with a status code outside the range of 2xx
          console.error("Error Data:", error.response.data);
          console.error("Error Status:", error.response.status);
          console.error("Error Headers:", error.response.headers);
          toast.error(
            `Error: ${error.response.data.message || "Something went wrong"}`
          );
        } else if (error.request) {
          // The request was made but no response was received
          console.error("Error Request:", error.request);
          toast.error("No response from server. Please try again later.");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error", error.message);
          toast.error("An error occurred. Please try again.");
        }
      });
  };
  return (
    <div>
      <div className="visitor-form-container">
        <div className="visitor-items-h">Company Information</div>
        <div className="visitor-items-c">
          <div className="visitor-items-first">
            <TextField
              size="small"
              name="companyName"
              value={profile.companyName}
              onChange={handleInputChange}
              label="Company Name*"
              variant="outlined"
              fullWidth
            />
          </div>
          <div className="visitor-items-sec">
            <TextField
              size="small"
              name="address"
              value={profile.address}
              onChange={handleInputChange}
              label="Address*"
              variant="outlined"
              fullWidth
            />
          </div>
        </div>
        <div className="visitor-items-c">
          <div className="visitor-items-first" id="selectedCountry">
            <CountriesCom
              state={profile}
              handleStateChange={handleStateChange}
            />
          </div>
          <div className="visitor-items-sec" id="selectedState">
            <StatesCom state={profile} handleStateChange={handleStateChange} />
          </div>
        </div>
        <div className="visitor-items-c">
          <div className="visitor-items-first" id="selectedCity">
            <CitiesCom state={profile} handleStateChange={handleStateChange} />
          </div>
          <div className="visitor-items-sec" id="pin">
            <TextField
              size="small"
              name="pin"
              value={profile.pin}
              onChange={handleInputChange}
              label="Pin/Zip*"
              variant="outlined"
              fullWidth
            />
          </div>
        </div>
        <div className="visitor-items-c">
          <div className="visitor-items-first">
            <TextField
              size="small"
              name="website"
              value={profile.website}
              onChange={handleInputChange}
              label="Website"
              variant="outlined"
              fullWidth
            />
          </div>
          <div className="visitor-items-sec">
            <TextField
              size="small"
              name="telephone"
              value={profile.telephone}
              onChange={handleInputChange}
              label="Telephone No."
              variant="outlined"
              fullWidth
            />
          </div>
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        <LoadingButton
          onClick={save}
          loading={saving}
          sx={{ m: 2, width: 200 }}
          variant="contained"
        >
          Save
        </LoadingButton>
      </div>
    </div>
  );
};

export default CompanyInfoEdit;
