import React, { useState } from "react";
import RdLoadingButton from "../../../../widgets/RdLoadingButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ProductItem from "../../../../widgets/ProductItem";
import AddProduct from "../AddProduct";
import RdMyModal from "../../../../widgets/myModal/RdMyModal";
import axios from "axios";
import {
  API_ENDPOINT,
  EXHIBITOR,
  EXHIBITOR_API,
  USER,
} from "../../../../constants/constants";
import { getExhibitorHeader, getHeader } from "../../../../utility/utility";
import CustomCircularP from "../../../../widgets/CustomCircularP";
import ProductDetail from "../ProductDetail";
import { Box, Button, IconButton, Popover, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import HeaderLabel from "../../../../widgets/HeaderLabel";
import AddBtn from "../../../../widgets/actionBtn/addBtn";
import SimpleYesNoDialog from "../../../../widgets/SimpleYesNoDialog";
import useProducts from "../hooks/useProducts";
import MuPb from "../../../../widgets/MuPb";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { StatusCodes } from "http-status-codes";
import InfoIcon from "@mui/icons-material/Info";
const Products = ({ exhibitor, isView = false, id }) => {
  const PARENT_VIEW = "parent_view";
  const DETAIL_VIEW = "detail_view";
  const [currentView, setCurrentView] = useState(PARENT_VIEW);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const { data, isLoading, isError, error, refetchProducts } = useProducts(
    page,
    id
  );
  const [showAddProduct, setShowAddProduct] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [currentMode, setCurrentMode] = useState("add");
  const [deleting, setDeleting] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const onAddProduct = () => {
    setCurrentMode("add");
    setCurrentProduct(null);
    setShowAddProduct(true);
  };
  const onEditProduct = (product) => {
    setCurrentMode("edit");
    setCurrentProduct(product);
    setShowAddProduct(true);
  };

  const onDeleteProduct = (product) => {
    setCurrentProduct(product);
    setShowDelete(true);
  };
  const onProductAdded = () => {
    //setReloadProfile((prevState) => !prevState);
    refetchProducts();
    setShowAddProduct(false);
  };
  const onSelectProduct = (product) => {
    setCurrentProduct(product);
    setCurrentView(DETAIL_VIEW);
  };
  const onYesDelete = async () => {
    setDeleting(true);
    try {
      const response = await axios.delete(
        `${API_ENDPOINT}${USER}/delete-product?id=${currentProduct._id}`,
        getHeader()
      );
      setDeleting(false);
      if (response.status === StatusCodes.OK) {
        setShowDelete(false);
        toast.success(response.data.message);
        onProductAdded();
      }
    } catch (error) {
      setDeleting(false);
      console.log(error);
      toast.error(error.message);
    }
  };
  if (isLoading) {
    return <MuPb />;
  }
  const onBack = () => {
    setCurrentView(PARENT_VIEW);
  };
  return (
    <div
      style={{
        backgroundColor: "white",
        padding: "16px",
        borderRadius: "8px",
      }}
    >
      <CustomCircularP show={deleting} />
      <RdMyModal
        showModal={showAddProduct}
        setShowModal={setShowAddProduct}
        modalC={
          <AddProduct
            mode={currentMode}
            onAdded={onProductAdded}
            showAddProduct={showAddProduct}
            product={currentProduct}
          />
        }
      />
      <SimpleYesNoDialog
        title="Please Confirm!"
        message="Are you sure you want to delete this?"
        onYes={onYesDelete}
        show={showDelete}
        setShow={setShowDelete}
      />
      {currentView === PARENT_VIEW ? (
        <Box>
          <Box sx={{ display: "flex" }}>
            <HeaderLabel onEdit={null} showEdit={false} label={"Products"} />
            <div style={{ flex: 1 }}></div>
            {!isView && <AddBtn onClick={onAddProduct} label={"Add Product"} />}
          </Box>
          <Box>
            {data?.data.map((product, i) => (
              <ProductItem
                onSelectProduct={onSelectProduct}
                viewMode={isView}
                onEditProduct={onEditProduct}
                onDeleteProduct={onDeleteProduct}
                key={i}
                product={product}
              />
            ))}

            {data?.data?.length === 0 && (
              <div
                style={{
                  margin: "16px",
                  fontSize: "20px",
                  color: "#888",
                  fontWeight: 600,
                }}
              >
                No Products Found
              </div>
            )}
          </Box>
        </Box>
      ) : (
        <Box>
          <Button
            onClick={onBack}
            variant="text"
            sx={{
              margin: "12px 0",
              textTransform: "none",
              "& .MuiButton-startIcon": {
                marginRight: 0, // Adjust the value as per your needs
              },
            }}
            size="small"
            startIcon={<ArrowBackIosIcon />}
          >
            Back
          </Button>
          <ProductDetail exhibitor={exhibitor} product={currentProduct} />
        </Box>
      )}
    </div>
  );
};

export default Products;
