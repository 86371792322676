import React, { useEffect, useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Hidden,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import "./PreviewPageRegister.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import OtpInput from "react-otp-input";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RdDesignationsSelect from "./RdDesignationsSelect";
import RdInductrySectory from "./RdInductrySectory";
import RdDepartment from "./RdDepartment";
import CountriesCom from "./CountriesCom";
import StatesCom from "./StatesCom";
import CitiesCom from "./CitiesCom";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import RdEventInfoSources from "./RdEventInfoSources";
import { validateEmail } from "../../utils/utils";
import { toast } from "react-toastify";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import DoneIcon from "@mui/icons-material/Done";
import { useNavigate } from "react-router-dom";
import mad2 from "../../../assets/mad3.jpg";
import { API_ENDPOINT } from "../../../constants/constants";
import { TITLES } from "../../../utility/utility";
import xLogo from "../../../assets/x_logo.png";
import CategoryAccordion from "./CategoryAccordion";
const RegInputs = ({
  state,
  setState,
  setCaptchaClicked,
  handleStateChange,
  emailVerified,
  setEmailVerified,
}) => {
  const navigate = useNavigate();

  const options = ["PMTX 2024", "FACTEQ 2024"];

  //const [checked, setChecked] = React.useState([]);

  const handleChange = (event) => {
    const index = state.eventsList.indexOf(event.target.name);
    let newChecked = [...state.eventsList];

    if (index === -1) {
      newChecked.push(event.target.name);
    } else {
      newChecked.splice(index, 1);
    }

    handleStateChange("eventsList", newChecked);
  };

  const {
    fbCheck,
    instaCheck,
    twitterCheck,
    linkedInCheck,
    fbLink,
    instaLink,
    twitterLink,
    linkedInLink,
  } = state;
  const [emailSent, setEmailSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [verifyingEmail, setVerifyingEmail] = useState(false);
  const handleCheckboxChange = (event) => {
    handleStateChange(event.target.name, event.target.checked);
  };
  const handleInputChange = (event) => {
    handleStateChange(event.target.name, event.target.value);
  };
  const verifyBtnStyles = {
    backgroundColor: emailVerified ? "green" : "#054054",
    color: "#FFFFFF",
    textTransform: "none",
  };
  const onVerifyEmail = () => {
    if (!emailVerified) {
      if (emailSent) {
        verifyOTP();
      } else {
        verifyEmail();
      }
    }
  };

  useEffect(() => {
    if (otp.length === 4) {
      verifyOTP();
    }
  }, [otp]);

  function verifyEmail() {
    if (state.email !== "") {
      if (!validateEmail(state.email)) {
        toast("Please Entrer Valid Email");
      } else {
        setVerifyingEmail(true);
        axios
          .get(API_ENDPOINT + `verifications/send-otp/${state.email}`)
          .then((res) => {
            setVerifyingEmail(false);
            const d = res.data;
            if (d.success) {
              if (d.verified) {
                setEmailVerified(true);
              } else {
                setEmailSent(true);
              }
            } else {
              toast(d.message);
            }
          });
      }
    } else {
      toast("Please Entrer Email Id first");
    }
  }
  function verifyOTP() {
    if (state.email !== "" && validateEmail(state.email) && otp.length == 4) {
      setVerifyingEmail(true);
      axios
        .get(API_ENDPOINT + `verifications/verify-otp/${state.email}/${otp}`)
        .then((res) => {
          setVerifyingEmail(false);
          const d = res.data;
          if (d.success) {
            setEmailVerified(true);
          } else {
            toast(d.message);
          }
        });
    } else {
      toast("Please Entrer Email Id first");
    }
  }
  const onChangeCaptcha = (value) => {
    console.log("Captcha value:", value);
    setCaptchaClicked(true);
  };
  return (
    <>
      <div className="visitor-form-container">
        <div className="visitor-items-h">Basic Information</div>
        <div className="visitor-items-c">
          <div className="visitor-items-first">
            <div style={{ display: "flex" }}>
              <FormControl
                sx={{ width: "115px", marginRight: "4px" }}
                size="small"
              >
                <InputLabel id="demo-simple-select-label">Title*</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={state.title}
                  name="title"
                  label="Title*"
                  onChange={handleInputChange}
                >
                  {TITLES.map((position, i) => (
                    <MenuItem key={i} value={position}>
                      {position}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                required
                size="small"
                name="firstName"
                value={state.firstName}
                onChange={handleInputChange}
                label="First Name"
                variant="outlined"
                fullWidth
              />
            </div>
          </div>
          <div className="visitor-items-sec">
            <TextField
              required
              size="small"
              name="lastName"
              value={state.lastName}
              onChange={handleInputChange}
              label="Last Name"
              variant="outlined"
              fullWidth
            />
          </div>
        </div>
        <div className="visitor-items-c">
          <div className="visitor-items-first" id="designation">
            <RdDesignationsSelect
              state={state}
              handleStateChange={handleStateChange}
            />
          </div>
          <div
            className="visitor-items-sec"
            id="mobileNumber"
            style={{ position: "relative" }}
          >
            {state.phone && state.phone.length > 0 && (
              <label
                style={{
                  position: "absolute",
                  top: "-12px",
                  left: "56px",
                  color: "#1876d1",
                  fontSize: "14px",
                  backgroundColor: "white",
                }}
                htmlFor="phone-input"
              >
                Mobile number*
              </label>
            )}
            <PhoneInput
              defaultCountry="IN"
              placeholder="Mobile number*"
              required
              name="phone"
              value={state.phone}
              onChange={(phone) => handleStateChange("phone", phone)}
            />
          </div>
        </div>
        <div className="visitor-items-c">
          <div className="visitor-items-first">
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1 }}>
                <TextField
                  required
                  disabled={emailVerified}
                  size="small"
                  name="email"
                  value={state.email}
                  onChange={handleInputChange}
                  label="Email"
                  variant="outlined"
                  fullWidth
                />
              </div>
            </div>
            {emailSent && !emailVerified && (
              <div className="react-otp-l">
                <div>Please Enter the OTP sent to your Email:</div>
                <OtpInput
                  isDisabled={emailVerified || verifyingEmail}
                  value={otp}
                  onChange={setOtp}
                  numInputs={4}
                  separator={<span>&nbsp;&nbsp;</span>}
                  renderInput={(props) => <input {...props} />}
                />
              </div>
            )}
          </div>
          <div className="visitor-items-sec">
            <LoadingButton
              sx={{ display: "none" }}
              loading={verifyingEmail}
              disabled={emailVerified}
              onClick={onVerifyEmail}
              variant="contained"
              style={verifyBtnStyles}
              startIcon={emailVerified ? <DoneIcon /> : null}
            >
              {emailVerified
                ? "Verified"
                : emailSent
                ? "Verify OTP"
                : "Verify Email"}
            </LoadingButton>
          </div>
        </div>
      </div>
      <Hidden smUp>
        <a href="https://www.imtex.in/" target="_blank">
          <img src={mad2} alt="IMTEX ad" width="100%" />
        </a>
      </Hidden>
      <div className="visitor-form-container">
        <div className="visitor-items-h">Company Information</div>
        <div className="visitor-items-c">
          <div className="visitor-items-first">
            <TextField
              size="small"
              name="companyName"
              value={state.companyName}
              onChange={handleInputChange}
              label="Company Name*"
              variant="outlined"
              fullWidth
            />
          </div>
          <div className="visitor-items-sec">
            <TextField
              size="small"
              name="address"
              value={state.address}
              onChange={handleInputChange}
              label="Address*"
              variant="outlined"
              fullWidth
            />
          </div>
        </div>
        <div className="visitor-items-c">
          <div className="visitor-items-first" id="selectedCountry">
            <CountriesCom state={state} handleStateChange={handleStateChange} />
          </div>
          <div className="visitor-items-sec" id="selectedState">
            <StatesCom state={state} handleStateChange={handleStateChange} />
          </div>
        </div>
        <div className="visitor-items-c">
          <div className="visitor-items-first" id="selectedCity">
            <CitiesCom state={state} handleStateChange={handleStateChange} />
          </div>
          <div className="visitor-items-sec" id="pin">
            <TextField
              size="small"
              name="pin"
              value={state.pin}
              onChange={handleInputChange}
              label="Pin/Zip*"
              variant="outlined"
              fullWidth
            />
          </div>
        </div>
        <div className="visitor-items-c">
          <div className="visitor-items-first">
            <TextField
              size="small"
              name="website"
              onChange={handleInputChange}
              label="Website"
              variant="outlined"
              fullWidth
            />
          </div>
          <div className="visitor-items-sec">
            <TextField
              size="small"
              name="telephone"
              value={state.telephone}
              onChange={handleInputChange}
              label="Telephone No."
              variant="outlined"
              fullWidth
            />
          </div>
        </div>
      </div>
      <div className="visitor-form-container">
        <div className="visitor-items-h">Professional Information</div>
        <div className="visitor-items-c">
          <div className="visitor-items-first" id="industrySector">
            <RdInductrySectory
              state={state}
              handleStateChange={handleStateChange}
            />
          </div>
          <div className="visitor-items-sec" id="department">
            <RdDepartment state={state} handleStateChange={handleStateChange} />
          </div>
        </div>
        <div className="visitor-items-c">
          <div className="visitor-items-first" id="eventSources">
            <RdEventInfoSources
              state={state}
              handleStateChange={handleStateChange}
            />
          </div>
        </div>
      </div>
      <div className="visitor-form-container">
        <div id="events_list" className="visitor-items-h">
          You will be visiting...
        </div>
        <FormGroup sx={{ mb: 2 }}>
          <Grid container>
            {options.map((option, index) => (
              <Grid item lg={6} sx={12} key={option}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.eventsList.indexOf(option) !== -1}
                      onChange={handleChange}
                      name={option}
                    />
                  }
                  label={<Typography fontWeight="bold">{option}</Typography>}
                />
              </Grid>
            ))}
          </Grid>
        </FormGroup>
        <div className="visitor-items-h" id="productInfo">
          Product Information
        </div>
        <div className="visitor-items-c" id="selectedCategories">
          <div style={{ marginTop: "0" }} className="visitor-items-first">
            {/* <RdAutoComplete
              state={state}
              handleStateChange={handleStateChange}
            /> */}
            <div style={{ margin: "8px 0" }}>
              Add the product categories that interest you*
            </div>
            {/* <CategoriesSelect
              state={state}
              handleStateChange={handleStateChange}
            /> */}

            <CategoryAccordion state={state} setState={setState} />
          </div>
        </div>
        {/* <div className='visitor-items-c'>
                <div className='visitor-items-first'><TextField multiline maxRows={4} label="Add a Note" variant="outlined" fullWidth/></div>
            </div> */}
      </div>
      <div className="visitor-form-container">
        <div className="visitor-items-h">Social Links</div>
        <div
          style={{
            color: "rgba(0, 0, 0, 0.85)",
            fontSize: "14px",
            lineHeight: 1.5,
          }}
        >
          Please Add the social links
        </div>
        <div className="visitor-social-links">
          <div style={{ display: "flex" }}>
            <Checkbox
              checked={fbCheck}
              onChange={handleCheckboxChange}
              name="fbCheck"
            />
            <FacebookIcon sx={{ alignSelf: "center", color: "#1877f2" }} />
          </div>
          <div style={{ display: "flex", marginLeft: "10px" }}>
            <Checkbox
              checked={instaCheck}
              onChange={handleCheckboxChange}
              name="instaCheck"
            />
            <InstagramIcon sx={{ alignSelf: "center", color: "#E4405F" }} />
          </div>
          <div style={{ display: "flex", marginLeft: "10px" }}>
            <Checkbox
              checked={twitterCheck}
              onChange={handleCheckboxChange}
              name="twitterCheck"
            />
            {/* <TwitterIcon sx={{ alignSelf: "center", color: "#1DA1F2" }} />
             */}
            <img
              src={xLogo}
              style={{
                width: 20,
                height: 20,
                display: "block",
                alignSelf: "center",
              }}
            />
          </div>
          <div style={{ display: "flex", marginLeft: "10px" }}>
            <Checkbox
              checked={linkedInCheck}
              onChange={handleCheckboxChange}
              name="linkedInCheck"
            />
            <LinkedInIcon sx={{ alignSelf: "center", color: "#0A66C2" }} />
          </div>
        </div>
        <div className="visitor-link-container">
          <div style={{ display: fbCheck ? "block" : "none" }}>
            <TextField
              size="small"
              label="Enter Facebook Link"
              fullWidth
              value={fbLink}
              onChange={handleInputChange}
              name="fbLink"
            />
          </div>
          <div style={{ display: instaCheck ? "block" : "none" }}>
            <TextField
              size="small"
              label="Enter Instagram Link"
              fullWidth
              value={instaLink}
              onChange={handleInputChange}
              name="instaLink"
            />
          </div>
          <div style={{ display: twitterCheck ? "block" : "none" }}>
            <TextField
              size="small"
              label="Enter Twitter Link"
              fullWidth
              value={twitterLink}
              onChange={handleInputChange}
              name="twitterLink"
            />
          </div>
          <div style={{ display: linkedInCheck ? "block" : "none" }}>
            <TextField
              size="small"
              label="Enter LinkedIn Link"
              fullWidth
              value={linkedInLink}
              onChange={handleInputChange}
              name="linkedInLink"
            />
          </div>
        </div>

        {/* <div
          style={{
            display: "flex",
            marginTop: "16px",
            justifyContent: "center",
          }}
        >
          <ReCAPTCHA
            sitekey="6Ldawf0mAAAAAIAefCR0Fh6JziSYN4SZMa3M0lto"
            onChange={onChangeCaptcha}
          />
        </div> */}
      </div>
    </>
  );
};

export default RegInputs;
