import React, { useEffect } from "react";
import "./PreviewPageRegister.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-phone-number-input/style.css";
import { Chip } from "@mui/material";

const PreviewComponent = ({ state }) => {
  return (
    <>
      <div className="visitor-form-container">
        <div className="visitor-items-h">Basic Information</div>
        <div className="visitor-items-c">
          <div className="visitor-items-first">
            <strong>
              <label style={{ marginRight: "10px" }}>First Name:</label>
            </strong>
            <span>{state.firstName}</span>
          </div>
          <div className="visitor-items-sec">
            <strong>
              <label style={{ marginRight: "10px" }}>Last Name:</label>
            </strong>
            <span>{state.lastName}</span>
          </div>
        </div>
        <div className="visitor-items-c">
          <div className="visitor-items-first">
            <strong>
              <label style={{ marginRight: "10px" }}>Designation:</label>
            </strong>
            <span>{state.designation}</span>
          </div>
          <div className="visitor-items-sec">
            <strong>
              <label style={{ marginRight: "10px" }}>Phone Number:</label>
            </strong>
            <span>{state.phone}</span>
          </div>
        </div>
        <div className="visitor-items-c">
          <div className="visitor-items-first">
            <strong>
              <label style={{ marginRight: "10px" }}>Email:</label>
            </strong>
            <span>{state.email}</span>
          </div>
          <div className="visitor-items-sec"></div>
        </div>
      </div>
      <div className="visitor-form-container">
        <div className="visitor-items-h">Company Information</div>
        <div className="visitor-items-c">
          <div className="visitor-items-first">
            <strong>
              <label style={{ marginRight: "10px" }}>Company Name:</label>
            </strong>
            <span>{state.companyName}</span>
          </div>
          <div className="visitor-items-sec">
            <strong>
              <label style={{ marginRight: "10px" }}>Address:</label>
            </strong>
            <span>{state.address}</span>
          </div>
        </div>
        <div className="visitor-items-c">
          <div className="visitor-items-first">
            <strong>
              <label style={{ marginRight: "10px" }}>Country:</label>
            </strong>
            <span>{state.selectedCountry.name}</span>
          </div>
          <div className="visitor-items-sec">
            <strong>
              <label style={{ marginRight: "10px" }}>Pin/Zip:</label>
            </strong>
            <span>{state.pin}</span>
          </div>
        </div>
        <div className="visitor-items-c">
          <div className="visitor-items-first">
            <strong>
              <label style={{ marginRight: "10px" }}>State:</label>
            </strong>
            <span>{state.selectedState.name}</span>
          </div>
          <div className="visitor-items-sec">
            <strong>
              <label style={{ marginRight: "10px" }}>Website:</label>
            </strong>
            <span>{state.website}</span>
          </div>
        </div>
        <div className="visitor-items-c">
          <div className="visitor-items-first">
            <strong>
              <label style={{ marginRight: "10px" }}>City:</label>
            </strong>
            <span>{state.selectedCity}</span>
          </div>
          <div className="visitor-items-sec">
            <strong>
              <label style={{ marginRight: "10px" }}>Telephone No.:</label>
            </strong>
            <span>{state.telephone}</span>
          </div>
        </div>
      </div>
      <div className="visitor-form-container">
        <div className="visitor-items-h">Professional Information</div>
        <div className="visitor-items-c">
          <div className="visitor-items-first">
            <strong>
              <label style={{ marginRight: "10px" }}>Industry Sector:</label>
            </strong>
            <span>{state.selectedIndustrySector}</span>
          </div>
          <div className="visitor-items-sec">
            <strong>
              <label style={{ marginRight: "10px" }}>Department:</label>
            </strong>
            <span>{state.department}</span>
          </div>
        </div>
        <div className="visitor-items-c">
          <div className="visitor-items-first">
            <strong>
              <label style={{ marginRight: "10px" }}>Event Info Source:</label>
            </strong>
            <span>{state.selectedEventSource}</span>
          </div>
        </div>
      </div>
      <div className="visitor-form-container">
        <div className="visitor-items-h">You will be visiting...</div>
        <div className="visitor-items-c">
          <div style={{ marginTop: "0" }} className="visitor-items-first">
            <strong>
              <label style={{ marginRight: "10px" }}>Events:</label>
            </strong>
            <span>{state.eventsList.join(", ")}</span>
          </div>
        </div>
      </div>
      <div className="visitor-form-container">
        <div className="visitor-items-h">Product Information</div>
        <div className="visitor-items-c">
          <div style={{ marginTop: "0" }} className="visitor-items-first">
            <strong>
              <label style={{ marginRight: "10px" }}>Product Categories:</label>
            </strong>
            <span>
              {Object.entries(state.selectedCategories || {}).flatMap(
                ([mainCategory, subCategories]) =>
                  subCategories.map((subCategory) => (
                    <Chip
                      key={`${mainCategory}-${subCategory}`}
                      label={`${mainCategory}: ${subCategory}`}
                      style={{ marginRight: "5px", marginBottom: "5px" }} // Adjust the margin as needed
                    />
                  ))
              )}
            </span>
          </div>
        </div>
      </div>
      <div className="visitor-form-container">
        <div className="visitor-items-h">Social Links</div>
        <div style={{ color: "#6D7C90" }}>Added Social links: </div>
        <div className="visitor-link-container">
          <div style={{ display: state.fbCheck ? "block" : "none" }}>
            <strong>
              <label style={{ marginRight: "10px" }}>Facebook Link:</label>
            </strong>
            <span>{state.fbLink}</span>
          </div>
          <div style={{ display: state.instaCheck ? "block" : "none" }}>
            <strong>
              <label style={{ marginRight: "10px" }}>Instagram Link:</label>
            </strong>
            <span>{state.instaLink}</span>
          </div>
          <div style={{ display: state.twitterCheck ? "block" : "none" }}>
            <strong>
              <label style={{ marginRight: "10px" }}>Twitter Link:</label>
            </strong>
            <span>{state.twitterLink}</span>
          </div>
          <div style={{ display: state.linkedInCheck ? "block" : "none" }}>
            <strong>
              <label style={{ marginRight: "10px" }}>LinkedIn Link:</label>
            </strong>
            <span>{state.linkedInLink}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviewComponent;
