import axios from "axios";
import React, { useEffect, useState } from "react";
import CustomCircularP from "../widgets/customCircularP";
import { Box, Typography } from "@mui/material";
import { CONFIRMATION_HEADER, EVENT_API } from "../../constants/constants";
import { BadgeToPrint } from "../../components/dashboard/visitor/BadgeToPrint";
import { getDbHeader } from "../../utils2024/utils";
const RegisrationQr = () => {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const loc = document.location.href.replace(/\/+$/, "");
  const keysUrl = loc.split("/");
  const linkCode = keysUrl[4];

  useEffect(() => {
    getUser();
  }, []);

  function getUser() {
    setLoading(true);
    axios
      .get(`${EVENT_API}get-user?uid=${linkCode}`, getDbHeader())
      .then((res) => {
        let d = res.data;
        setLoading(false);
        if (d.success) {
          setUser(d.message);

          const badgeToPrintBox = document.querySelector("#badge-to-print"); // Assuming a class is added
          if (badgeToPrintBox) {
            badgeToPrintBox.scrollIntoView({ behavior: "smooth" });
          }
        }
      });
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: "800px",
        margin: "20px auto",
        padding: "20px",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
      }}
    >
      <CustomCircularP show={loading} />
      <img
        src={CONFIRMATION_HEADER}
        style={{ display: "block", width: "100%", margin: "16px auto" }}
      />
      <p style={{ fontWeight: "bold", fontSize: "19px" }}>
        Thank you for registering!
      </p>
      <p>
        An email is sent to you confirming your registration for
        <strong> PUNE MACHINE TOOL EXPO 2024</strong>
      </p>
      <div id="badge-to-print">
        {user && <BadgeToPrint user={user} isPreview={true} />}
      </div>
      <Typography fontWeight={"bold"} mt={2} mb={2}>
        Show the above QR code @ Pune International Exhibition and Convention
        Center, Moshi, Pune and collect your physical BADGE
      </Typography>
      <Box px={2} py={3} borderRadius="borderRadius">
        <Typography variant="body1" gutterBottom>
          Once you activate the link you can login and start using the PMTX 2024
          online portal.
        </Typography>

        <Typography variant="body1" gutterBottom>
          Visitor Badge: You may use fast-track registration at the event, to do
          so please take the print copy of your badge from your profile, by
          using the Print Badge button.
        </Typography>

        <Typography variant="body1" gutterBottom>
          Advantages of Pre-registering on Expoplanner:
        </Typography>

        <ul>
          <Typography component="li" variant="body2">
            Avoiding queues
          </Typography>
          <Typography component="li" variant="body2">
            Safe & Secure entry to the venue
          </Typography>
          <Typography component="li" variant="body2">
            Know the products displayed
          </Typography>
          <Typography component="li" variant="body2">
            Search the booths from the floor plan
          </Typography>
          <Typography component="li" variant="body2">
            Connect and schedule your meetings
          </Typography>
        </ul>

        <Typography variant="h5" align="center" gutterBottom>
          IMTEX Team
        </Typography>
        <Typography sx={{ mt: 2 }} variant="body1" color="textSecondary">
          <b>Note:</b>If you haven't received our email, please check your spam
          or junk folder. Sometimes, emails might be mistakenly classified as
          spam.
        </Typography>
      </Box>
    </div>
  );
};

export default RegisrationQr;
