import "./App.css";
import * as React from "react";
import VisitorDashboard from "./components/dashboard/visitor/VisitorDashboard";
import ExhibitorDashboard from "./components/dashboard/exhibitor/ExhibitorDashboard";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivatePath from "./PrivatePath";
import FloorPlan from "./components/floorplan/FloorPlan";
import "react-circular-progressbar/dist/styles.css";
import Admin from "./components/dashboard/admin/Admin";
import PreviewFormContainer from "./visitorregt/components/previewpage/previewFormContainer";
import SetPasswordActivationPage from "./visitorregt/pages/PasswordSet";
import RegisrationQr from "./visitorregt/pages/RegisrationQr";
import TermsAndConditions from "./visitorregt/pages/termsConditions";
import Services from "./components/dashboard/exhibitor/oes/Services";
import ExhibitorFloorPlan from "./components/floorplan/made/ExhibitorFloorPlan";
import { QueryClient, QueryClientProvider } from "react-query";
import DrawPolygon from "./components/floorplan/DrawPolygon";
import RectangleCanvas from "./components/floorplan/RectangleCanvas";
import GeneralFloorPlan from "./components/floorplan/editor/GeneralFloorPlan";
import AddHall from "./components/floorplan/editor/AddHall";
import "react-quill/dist/quill.snow.css";
import ResponsiveUI from "./components/dashboard/exhibitor/ResponsiveUI";
import HallPlan from "./components/floorplan/editor/HallPlan";
import AddStand from "./components/floorplan/editor/AddStand";
import HallView from "./components/floorplan/view/HallView";
import BadgePrinter from "./components/badgeprinter/BadgePrinter";
import ScanBadge from "./components/badgeprinter/ScanBadge";
import OnSiteDashboard from "./components/onsite/OnSiteDashboard";
import SpotRegistration from "./components/onsite/SpotRegistration";
import SpotUsers from "./components/onsite/SpotUsers";
import SecurityScan from "./components/badgeprinter/SecurityScan";
import VisitorStatsTable from "./components/onsite/VisitorStatsTable";
import StudentRegistration from "./pages/StudentRegistration";
import ServiceBadge from "./components/onsite/service/ServiceBadge";
import ServicePerson from "./components/onsite/service/ServicePerson";
import ServiceWelcomeMessage from "./components/onsite/service/ServiceWelcomeMessage";
import ServiceBadgePreview from "./components/onsite/service/ServiceBadgePreview";
import ScanService from "./components/onsite/service/ScanService";
import BulkPrint from "./components/badgeprinter/bulk/BulkPrint";
import BulkStudent from "./components/badgeprinter/bulk/BulkStudent";
import MeetingScheduler from "./components/dashboard/exhibitor/meeting/MeetingScheduler";
import ChatApp from "./components/chatApp/ChatApp";
import MeetingDetail from "./components/dashboard/exhibitor/meeting/MeetingDetail";
import OrganiserBadges from "./components/badgeprinter/bulk/OrganiserBadges";
import FootFall from "./FootFall";
import FootFallbyTime from "./FootFallbyTime";
import Hall1ScanService from "./components/onsite/service/Hall1ScanService";
import RepeatFootFall from "./RepeatFootFall";
import FootFallHallOne from "./FootFallHallOne";
import FootfallLinks from "./FootFallLinks";
import RepeatFootFallHallOne from "./RepeatFootFallHallOne";
import FootFallVisitCompare from "./FootFallVisitCompare";
import OrgAdmin from "./organiser/OrgAdmin";
import EventSettings from "./organiser/event-settings/eventSettings";
import HomePage from "./home/HomePage";
import { ThemeProvider } from "@mui/material";
import { ExpoTheme } from "./theme/theme";
import { detectSubdomainAndDash } from "./utility/utility";
import Login from "./components/dashboard/common/Login";
import EventLogin from "./components/events/Login";
import SuperHome from "./home/SuperHome";
import EventHome from "./components/events/EventHome";
import {
  ExhibitorAuth,
  GROUP_ONE,
  GROUP_TWO,
  OnsiteAuth,
  VisitorAuth,
} from "./utils2024/utils";
import ScheduleMeeting from "./components/dashboard/exhibitor/meeting/ScheduleMeeting";
import MobileHallView from "./components/floorplan/view/MobileHallView";
import { ViewBadgeMobile } from "./components/dashboard/visitor/ViewBadgeMobile";
import ActivateOnsite from "./components/onsite/ActivateOnsite";
import OnsiteActivator from "./components/onsite/OnsiteActivator";
import GroupOne from "./components/onsite/GroupOne";
import ActivateOnsite1 from "./components/onsite/ActivateOnsite1";
import Pmtxfootfall from "./pages/Pmtxfootfall";
const queryClient = new QueryClient();
function DomainRedirect() {
  const navigate = useNavigate();
  React.useEffect(() => {
    const hostname = window.location.hostname;
    const pathname = window.location.pathname;
    if (pathname === "/") {
      const domainCheck = detectSubdomainAndDash(hostname);
      if (domainCheck.hasSubdomain) {
        if (domainCheck.hasDash) {
          navigate("/event");
        } else {
          navigate("/organiser");
        }
      } else {
        navigate("/");
      }
    }
  }, [navigate]);

  return null;
}
function App() {
  return (
    <ThemeProvider theme={ExpoTheme}>
      <QueryClientProvider client={queryClient}>
        <div className="App">
          <BrowserRouter>
            <ToastContainer autoClose={1500} />
            <Routes>
              <Route exact path="/" element={<HomePage />} />
              <Route exact path="/super/manage/*" element={<SuperHome />} />
              <Route exact path="/organiser/manage/*" element={<OrgAdmin />} />
              <Route exact path="/event/*" element={<EventHome />} />

              <Route exact path="/super/login" element={<Login />} />
              <Route exact path="/organiser/login" element={<Login />} />
              <Route exact path="/admin/*" element={<Admin />} />
              <Route path="/on-site" element={<OnSiteDashboard />} />
              <Route
                path="/on-site/visitor-stats"
                element={<VisitorStatsTable />}
              />
              <Route path="/service-badge" element={<ServiceBadge />} />
              <Route path="/service-badge/:id" element={<ServicePerson />} />
              <Route
                path="/service-badge-welcome/:id"
                element={<ServiceWelcomeMessage />}
              />
              <Route
                path="/service-badge-preview/:id"
                element={<ServiceBadgePreview />}
              />
              <Route path="/meeting-scheduler" element={<ScheduleMeeting />} />
              <Route path="/service-scan" element={<ScanService />} />
              <Route
                path="/security-scan-hall-one"
                element={<Hall1ScanService />}
              />
              <Route path="/footfall-count" element={<FootFall />} />
              <Route path="/footfall-links" element={<FootfallLinks />} />
              <Route path="/footfall-by-time" element={<FootFallbyTime />} />
              <Route
                path="/traffic-comparison"
                element={<FootFallVisitCompare />}
              />
              <Route
                path="/footfall-by-time-hall-one"
                element={<FootFallHallOne />}
              />
              <Route
                path="/footfall-with-repeat"
                element={<RepeatFootFall />}
              />
              <Route
                path="/footfall-with-repeat-hall-one"
                element={<RepeatFootFallHallOne />}
              />
              <Route path="/test-mui-dash" element={<ResponsiveUI />} />
              <Route path="/expoplanner-messaging" element={<ChatApp />} />
              <Route
                path="/floor-plan-creator"
                element={<GeneralFloorPlan />}
              />
              <Route path="/hall-plan/:id" element={<HallPlan />} />
              <Route path="/edit-hall/:id" element={<AddHall />} />
              <Route path="/fp-add-hall" element={<AddHall />} />
              <Route path="/fp-add-stand/:id" element={<AddStand />} />
              <Route path="/floor-plan" element={<FloorPlan />} />
              <Route path="/floor-plan-poly" element={<DrawPolygon />} />
              <Route path="/floor-plan-rect" element={<RectangleCanvas />} />
              <Route path="/hall-view/:id" element={<HallView />} />
              <Route path="/view-my-badge" element={<ViewBadgeMobile />} />
              <Route
                path="/mobile-hall-view/:id"
                element={<MobileHallView />}
              />
              <Route
                path="/exhibitor-floor-plan"
                element={<ExhibitorFloorPlan />}
              />
              <Route
                path="/visitor"
                element={
                  <VisitorAuth>
                    <VisitorDashboard />
                  </VisitorAuth>
                }
              />
              <Route
                path="/visitor/:page"
                element={
                  <PrivatePath>
                    <VisitorDashboard />
                  </PrivatePath>
                }
              />
              <Route
                path="/visitor/:page/:page"
                element={
                  <PrivatePath>
                    <VisitorDashboard />
                  </PrivatePath>
                }
              />
              <Route
                path="/visitor/exhibitor-profile/:id"
                element={<VisitorDashboard />}
              />

              <Route
                exact
                path="/visitor-registration"
                element={<PreviewFormContainer />}
              />
              <Route
                exact
                path="/activation-link/:code"
                element={<SetPasswordActivationPage />}
              />
              <Route exact path="/meeting/:id" element={<MeetingDetail />} />
              <Route exact path="/badge-printer" element={<BadgePrinter />} />
              <Route
                exact
                path="/onsite-users"
                element={
                  <OnsiteAuth>
                    {" "}
                    <SpotUsers />
                  </OnsiteAuth>
                }
              />
              <Route
                exact
                path="/group-one-onsite/*"
                element={
                  <OnsiteAuth type={GROUP_ONE}>
                    {" "}
                    <GroupOne />
                  </OnsiteAuth>
                }
              />
              <Route
                exact
                path="/activate-onsite1"
                element={<ActivateOnsite1 />}
              />
              <Route
                exact
                path="/spot-registration"
                element={
                  <OnsiteAuth type={GROUP_ONE}>
                    <SpotRegistration />
                  </OnsiteAuth>
                }
              />
              <Route
                exact
                path="/scan-badge"
                element={
                  <OnsiteAuth>
                    <ScanBadge />
                  </OnsiteAuth>
                }
              />

              <Route
                path="/security-scan"
                element={
                  <OnsiteAuth type={GROUP_TWO}>
                    <SecurityScan />
                  </OnsiteAuth>
                }
              />
              {/* <Route path="/report-ff-imtma-pmtx" element={<Pmtxfootfall />} /> */}
              <Route
                exact
                path="/student-registration"
                element={<StudentRegistration />}
              />
              {/*<Route exact path="/dream-cast" element={<DreamCast />} /> */}
              <Route path="/reg-qr/:linkCode" element={<RegisrationQr />} />
              <Route
                path="/terms-conditions"
                element={<TermsAndConditions />}
              />

              <Route
                path="/exhibitor"
                element={
                  <ExhibitorAuth>
                    <ExhibitorDashboard />
                  </ExhibitorAuth>
                }
              />
              <Route path="/activate-onsite" element={<ActivateOnsite />} />
              <Route
                path="/my-onsite-activator/:id"
                element={<OnsiteActivator />}
              />
              <Route path="/exhibitor-services/:id" element={<Services />} />
              <Route path="/exhibitor/:page" element={<ExhibitorDashboard />} />
              <Route exact path="/ux-vb-rd-xxxx/:id" element={<SpotUsers />} />
              <Route
                path="/exhibitor/:page/:page"
                element={<ExhibitorDashboard />}
              />
              <Route path="/event-settings/*" element={<EventSettings />} />
            </Routes>
            <DomainRedirect />
          </BrowserRouter>
        </div>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
