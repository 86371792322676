import {
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import useStates from "../../../../../visitorregt/components/previewpage/hooks/useStates";

function StatesSelect({ state, handleStateChange }) {
  const { data, isLoading, isError, error } = useStates(
    state?.country?.isoCode
  );

  const handleInputChange = (event) => {
    // Find the full state object based on the selected isoCode
    const selectedState = data.find((s) => s.isoCode === event.target.value);
    // Update the state with the full state object
    handleStateChange(event.target.name, selectedState);
  };

  if (isLoading) {
    return <LinearProgress />;
  }

  // Determine the isoCode of the currently selected state for the Select's value
  const selectedIsoCode = state.state ? state.state.isoCode : "";

  return (
    <FormControl fullWidth size="small">
      <InputLabel id="state-select-label">Select State*</InputLabel>
      <Select
        labelId="state-select-label"
        id="state-select"
        name="state"
        value={selectedIsoCode}
        label="Select State*"
        onChange={handleInputChange}
      >
        {data &&
          data.map((target) => (
            <MenuItem key={target.isoCode} value={target.isoCode}>
              {target.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

export default StatesSelect;
