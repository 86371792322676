import React, { useState } from "react";
import VisitorCard from "../../../../widgets/VisitorCard";
import { Box, Grid, Pagination, Typography } from "@mui/material";
import useVisitors from "./hooks/useVisitors";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../../../widgets/searchBar";
import useCommonGetInit from "../../../events/hooks/useCommonGetInit";
import { USER } from "../../../../constants/constants";
import CustomCircularP from "../../../../widgets/CustomCircularP";
const Visitors = () => {
  const [page, setPage] = useState(1);
  const [sq, setSq] = useState("");
  const { data, isLoading, isError, error } = useCommonGetInit(
    `${USER}/get-visitors?page=${page}&sq=${sq}&`
  );
  const onPageChange = (e, page) => {
    setPage(page);
  };
  const onItemSearch = (e) => {
    setSq(e.target.value);
  };
  return (
    <Box>
      <CustomCircularP show={isLoading} />
      <Grid
        pl={2}
        pr={2}
        mt={2}
        mb={2}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h5" fontWeight={"bold"}>
            Visitors{" "}
          </Typography>
        </Grid>
        <Grid item>
          <SearchBar
            onSearch={onItemSearch}
            label="Search Visitors"
            size="7px"
            boxAlign="inline-flex"
            mystyle={{
              display: "inline-flex",
              backgroundColor: "white",
              width: "276px",
            }}
            cstyle={{ flex: 1 }}
          />
        </Grid>
      </Grid>
      <div style={{ display: "inline-block", gap: "12px" }}>
        {data?.data?.map((exh, i) => (
          <VisitorCard key={i} item={exh} />
        ))}
        {data?.data?.length === 0 && (
          <div className="items-not-found">No Data Yet</div>
        )}
      </div>

      <Box mt={2} mb={2} display={"flex"} justifyContent={"center"}>
        <Pagination
          count={Math.ceil(data?.totalItems / 30)}
          page={page}
          onChange={onPageChange}
          color="primary"
        />
      </Box>
    </Box>
  );
};

export default Visitors;
