import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Chip,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { Group, Image, Layer, Line, Rect, Stage, Text } from "react-konva";
import { useNavigate, useParams } from "react-router-dom";
import {
  DEFAULT_EX_ICON,
  TRANSPARENCY,
  USER,
} from "../../../constants/constants";
import { getHoverColor, hexToRGBA } from "../../../utility/utility";
import BackBtn from "../../dashboard/admin/widgets/BackBtn";
import useFloorPlan from "../hooks/useFloorPlan";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import ExhibitorDetails from "../editor/ExhibitorDetails";
import locationIcon from "../../../assets/location_icon.png";
import useCommonGetInit from "../../events/hooks/useCommonGetInit";
export const CONTAINER_WIDTH = 1280;
export const CONTAINER_HEIGHT = 560;
export const CONTAINER_AR = 1280 / 600;
export const getRelativePointerPosition = (node) => {
  const transform = node.getAbsoluteTransform().copy();
  transform.invert();
  const pos = node.getStage().getPointerPosition();
  return transform.point(pos);
};
const HallView = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [selectedChip, setSelectedChip] = useState(null);
  const { data, isLoading, isError, error } = useCommonGetInit(
    `${USER}/get-stands-hall-name?id=${id}&`
  );
  const { data: exhibitors } = useCommonGetInit(
    `${USER}/get-exhibitors-by-hall-name?hallId=${id}&`
  );
  const { data: hallsData, refetchFloorPlan: loadHalls } = useCommonGetInit(
    `${USER}/get-floor?id=1&`
  );
  const [image, setImage] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [stageScale, setStageScale] = useState(1);
  const [stageX, setStageX] = useState(0);
  const [stageY, setStageY] = useState(0);

  const [stagePos, setStagePos] = useState({ x: 0, y: 0 });
  const stageRef = useRef(null);

  const [chall, setChall] = useState();
  const [cstand, setCstand] = useState();

  const [hoverData, setHoverData] = useState({
    show: false,
    hall: null,
    stand: null,
    position: { x: 0, y: 0, width: 0, height: 0 },
  });
  const [lastDist, setLastDist] = useState(0);
  const [lastCenter, setLastCenter] = useState(null);

  const [selectCategory, setSelectCategory] = useState();
  const [selectCountry, setSelectCountry] = useState();
  const [countries, setCountries] = useState();
  const [categories, setCategories] = useState();
  const [locationImg, setLocationImg] = useState(null);
  const [filteredExhibitors, setFilteredExhibitors] = useState();

  useEffect(() => {
    const img = new window.Image();
    img.src = locationIcon; // Replace with your image path
    img.onload = () => setLocationImg(img);
  }, []);

  useEffect(() => {
    if (exhibitors) {
      const uniqueExhibitorCategories = new Set(
        exhibitors.flatMap((cat) => cat.exhibitorCategory.value)
      );
      setCategories([...uniqueExhibitorCategories]);
      const uniqueExhibitorCountries = new Set(
        exhibitors.flatMap((cat) => cat.country?.value?.name)
      );
      setCountries([...uniqueExhibitorCountries]);
    }
  }, [exhibitors]);
  useEffect(() => {
    // Logic to filter exhibitors based on selected category and country
    const filtered = exhibitors?.filter(
      (exhibitor) =>
        exhibitor?.country?.value?.name === selectCountry ||
        exhibitor.exhibitorCategory.value.includes(selectCategory)
    );
    setFilteredExhibitors(filtered);
  }, [selectCategory, selectCountry, exhibitors]);

  const isHallHighlighted = (hall) => {
    return filteredExhibitors?.some(
      (exhibitor) =>
        exhibitor.hallNumber.value === data.hall.name &&
        exhibitor.standNumber.value === hall.name
    );
  };

  const getDistance = (touch1, touch2) => {
    const dx = touch1.clientX - touch2.clientX;
    const dy = touch1.clientY - touch2.clientY;
    return Math.sqrt(dx * dx + dy * dy);
  };

  const getCenter = (touch1, touch2) => {
    return {
      x: (touch1.clientX + touch2.clientX) / 2,
      y: (touch1.clientY + touch2.clientY) / 2,
    };
  };

  const handleTouchMove = (e) => {
    e.evt.preventDefault();
    const stage = stageRef.current;
    const touches = e.evt.touches;

    if (touches.length === 2) {
      const newDist = getDistance(touches[0], touches[1]);
      if (lastDist) {
        const scaleRatio = newDist / lastDist;
        const newScale = stageScale * scaleRatio;

        const center = getCenter(touches[0], touches[1]);
        if (lastCenter) {
          const deltaX = center.x - lastCenter.x;
          const deltaY = center.y - lastCenter.y;

          const newPos = {
            x: stage.x() - deltaX * scaleRatio,
            y: stage.y() - deltaY * scaleRatio,
          };

          stage.scale({ x: newScale, y: newScale });
          stage.position(newPos);
          stage.batchDraw();
        }
        setLastCenter(center);
        setStageScale(newScale);
      }
      setLastDist(newDist);
    }
  };

  const handleTouchEnd = () => {
    setLastDist(0);
    setLastCenter(null);
  };

  const handleMouseEnter = (event, hall) => {
    setHoverData({
      show: true,
      hall: data.hall,
      stand: hall,
      position: {
        x: hall.shape.shape.x,
        y: hall.shape.shape.y,
        width: hall.shape.shape.width,
        height: hall.shape.shape.height,
      },
    });
  };

  const handleMouseLeave = () => {
    handleClose();
    // Optionally hide on mouse leave or keep until close is clicked
  };

  const handleClose = () => {
    setHoverData({ ...hoverData, show: false });
  };

  useEffect(() => {
    if (data) {
      setSelectedChip(data.hall.name);
      const img = new window.Image();
      img.src = data.hall.image;

      img.onload = () => {
        setImage({
          img: img,
          width: img.width,
          height: img.height,
        });
      };
    }
  }, [data]);

  const handleWheel = (e) => {
    e.evt.preventDefault();

    const scaleBy = 1.05;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();

    const pointer = stage.getPointerPosition();

    // Calculate the new scale
    const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;

    // Update the stage scale
    setStageScale(newScale);

    // Calculate the new position
    const newPos = {
      x: pointer.x - ((pointer.x - stage.x()) * newScale) / oldScale,
      y: pointer.y - ((pointer.y - stage.y()) * newScale) / oldScale,
    };

    setStagePos(newPos); // Update the stage position
    setStageX(newPos.x); // Assuming these are used for something else
    setStageY(newPos.y);
  };
  const HoverableChip = styled(Chip)({
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)", // Or any color you want for hover state
      cursor: "pointer", // Change cursor on hover
    },
  });
  const onSelected = (hall) => {
    setImage(null);
    navigate(`/hall-view/${hall.name}`, { replace: true });
  };
  const onViewExhibitor = (hall, stand) => {
    setChall(hall.name);
    setCstand(stand.name);
    setIsOpen(true);
  };
  function truncateText(text, maxWidth, fontSize) {
    const exhibitor = exhibitors?.find(
      (d) =>
        d.hallNumber.value === data.hall.name && d.standNumber.value === text
    );
    const name = exhibitor ? exhibitor.companyName.value : "";
    const approxCharWidth = fontSize * 0.6; // Approximate width of each character
    const maxChars = Math.floor(maxWidth / approxCharWidth);

    if (name.length > maxChars) {
      return name.substring(0, maxChars - 3) + ".."; // Subtract 3 for the ellipsis
    }
    return name;
  }

  return (
    <Box maxWidth={1280} m="8px auto">
      <Drawer
        anchor="left"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        sx={{
          width: "60%",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "60%",
            boxSizing: "border-box",
          },
        }}
      >
        <IconButton
          size="small"
          onClick={() => setIsOpen(false)}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            zIndex: 20,
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
        <Box>
          {chall && cstand && <ExhibitorDetails hall={chall} stand={cstand} />}
        </Box>
      </Drawer>
      <Box>
        <Box display="flex" alignItems="center" width="100%">
          <Grid pl={1} pr={1} container spacing={2} alignItems="center">
            <Grid item xs={12} sm={3}>
              <BackBtn />
            </Grid>

            <Grid item xs={12} sm={3}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap={2}
              >
                {hallsData?.halls?.map((hall, i) => (
                  <HoverableChip
                    key={i}
                    label={hall.name}
                    onClick={() => onSelected(hall)}
                    color={selectedChip === hall.name ? "primary" : "default"}
                  />
                ))}
              </Box>
            </Grid>

            <Grid item xs={12} sm={3}>
              <FormControl size="small" fullWidth>
                <InputLabel id="country-select-label">
                  Filter by Country
                </InputLabel>
                <Select
                  labelId="country-select-label"
                  id="country-select"
                  value={selectCountry}
                  onChange={(e) => setSelectCountry(e.target.value)}
                  label="Filter by Country"
                >
                  <MenuItem value="">None</MenuItem>
                  {countries?.map((m, i) => (
                    <MenuItem key={i} value={m}>
                      {m}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={3}>
              <FormControl size="small" fullWidth>
                <InputLabel id="category-select-label">
                  Filter by Category
                </InputLabel>
                <Select
                  labelId="category-select-label"
                  id="category-select"
                  value={selectCategory}
                  onChange={(e) => setSelectCategory(e.target.value)}
                  label="Filter by Category"
                >
                  <MenuItem value="">None</MenuItem>
                  {categories?.map((m, i) => (
                    <MenuItem key={i} value={m}>
                      {m}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        <Card
          variant="outlined"
          sx={{
            width: `${CONTAINER_WIDTH}px`,
            height: `${CONTAINER_HEIGHT}px`,
            mt: 1,
            ml: "auto",
            mr: "auto",
            overflow: "hidden",
            position: "relative",
          }}
        >
          {hoverData.show && (
            <HoverComponent
              allExhibitors={exhibitors}
              onExhibitorSelect={onViewExhibitor}
              hall={hoverData.hall}
              stand={hoverData.stand}
              position={hoverData.position}
              onClose={handleClose}
            />
          )}
          {image ? (
            <Stage
              width={CONTAINER_WIDTH}
              height={CONTAINER_HEIGHT}
              scaleX={stageScale}
              scaleY={stageScale}
              ref={stageRef}
              x={stagePos.x}
              y={stagePos.y}
              onWheel={handleWheel}
              draggable={true}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
            >
              <Layer>
                <Image
                  image={image.img}
                  width={CONTAINER_WIDTH}
                  height={CONTAINER_WIDTH * (image?.height / image?.width)}
                  x={(CONTAINER_WIDTH - CONTAINER_WIDTH) / 2} // Center horizontally
                  y={
                    (CONTAINER_HEIGHT -
                      CONTAINER_WIDTH * (image?.height / image?.width)) /
                    2
                  } // Center vertically
                />

                {data?.stands
                  ?.filter((hall) => hall.shape.type === "Polygon")
                  .map((hall) => (
                    <Group>
                      <Line
                        points={hall.shape.shape}
                        fill={hexToRGBA(hall.color, TRANSPARENCY)}
                        stroke={hexToRGBA(hall.color, TRANSPARENCY)}
                        strokeWidth={hall.strokeWidth}
                        closed={true}
                        onMouseEnter={(e) => {
                          const shape = e.target;
                          shape.fill(getHoverColor(hall.color)); // Change fill to a darker blue on hover
                          shape.getLayer().batchDraw(); // Redraw the layer for immediate visual feedback
                          handleMouseEnter(e, hall);
                        }}
                        onMouseLeave={(e) => {
                          const shape = e.target;
                          shape.fill(hexToRGBA(hall.color, TRANSPARENCY)); // Reset to the original color on mouse leave
                          shape.getLayer().batchDraw(); // Redraw the layer for immediate visual feedback
                          handleMouseLeave();
                        }}
                        onClick={() => {
                          //This is where your click logic goes
                          console.log(`Clicked on hall with id: ${hall}`);
                        }}
                      />
                    </Group>
                  ))}
                {data?.stands
                  ?.filter((hall) => hall.shape.type === "Rectangle")
                  .map((hall) => (
                    <Group>
                      <Rect
                        x={hall.shape.shape.x}
                        y={hall.shape.shape.y}
                        width={hall.shape.shape.width}
                        height={hall.shape.shape.height}
                        fill={hexToRGBA(hall.color, TRANSPARENCY)}
                        stroke={hexToRGBA(hall.color, TRANSPARENCY)}
                        strokeWidth={hall.strokeWidth}
                        onClick={(e) => {
                          onViewExhibitor(data.hall, hall);
                        }}
                        onTap={(e) => {
                          onViewExhibitor(data.hall, hall);
                        }}
                        onMouseEnter={(e) => {
                          const shape = e.target;
                          shape.fill(getHoverColor(hall.color)); // Change fill to a darker blue on hover
                          shape.getLayer().batchDraw(); // Redraw the layer for immediate visual feedback
                          handleMouseEnter(e, hall);
                        }}
                        onMouseLeave={(e) => {
                          const shape = e.target;
                          shape.fill(hexToRGBA(hall.color, TRANSPARENCY)); // Reset to the original color on mouse leave
                          shape.getLayer().batchDraw(); // Redraw the layer for immediate visual feedback
                          handleMouseLeave();
                        }}
                      />

                      {locationImg && isHallHighlighted(hall) && (
                        <Image
                          image={locationImg}
                          x={hall.shape.shape.x + 4}
                          y={hall.shape.shape.y - 10}
                          width={14}
                          height={20}
                        />
                      )}
                      <Text
                        text={truncateText(
                          hall.name,
                          hall.shape.shape.width,
                          8
                        )}
                        x={hall.shape.shape.x + 4}
                        y={hall.shape.shape.y + hall.shape.shape.height / 2 - 4} // Center text vertically
                        fill={"black"}
                        fontSize={8} // Adjust the font size
                        fontWeight="bold"
                      />
                    </Group>
                  ))}
              </Layer>
            </Stage>
          ) : (
            <Box sx={{ p: 3, width: "100%", height: "100%" }}>Loading ...</Box>
          )}
        </Card>
      </Box>
    </Box>
  );
};
const HoverComponent = ({
  allExhibitors,
  hall,
  onExhibitorSelect,
  stand,
  position,
  onClose,
}) => {
  const exhibitor = allExhibitors?.find(
    (exh) =>
      exh.hallNumber.value === hall.name && exh.standNumber.value === stand.name
  );
  const [cursorPosition, setCursorPosition] = useState({
    x: -20000,
    y: -20000,
  });
  const cardRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (e) => {
      let newX = e.clientX;
      let newY = e.clientY;
      if (cardRef.current) {
        const cardWidth = cardRef.current.offsetWidth;
        const cardHeight = cardRef.current.offsetHeight;

        // Adjust position to prevent overflow
        if (newX + cardWidth + 16 > window.innerWidth) {
          newX -= cardWidth + 16;
        } else {
          newX += 16; // 16px from cursor
        }

        if (newY + cardHeight + 16 > window.innerHeight) {
          newY -= cardHeight + 16;
        } else {
          newY += 16; // 16px from cursor
        }
      }
      setCursorPosition({ x: newX, y: newY });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <Card
      ref={cardRef}
      sx={{
        position: "fixed",
        left: cursorPosition.x,
        top: cursorPosition.y,
        zIndex: 100,
        // other styles as needed
      }}
    >
      <CardContent sx={{ mt: 2, maxWidth: 300 }}>
        <Box>
          <Paper
            onClick={() => onExhibitorSelect(hall, stand)}
            sx={{
              p: 2,
              display: "flex",
              alignItems: "center",
              gap: 2,
              cursor: "pointer", // Changes the cursor to indicate the element is clickable
              transition: "transform 0.3s ease-in-out", // Smooth transition for hover effect
              "&:hover": {
                transform: "scale(1.05)", // Scales up the Paper slightly on hover
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Add shadow effect on hover
              },
            }}
          >
            <Avatar
              src={
                exhibitor?.profile_image
                  ? exhibitor.profile_image
                  : DEFAULT_EX_ICON
              }
              alt={`${exhibitor?.companyName?.value} Logo`}
              sx={{ width: 56, height: 56 }}
            />
            <Box>
              <Typography
                variant="body1"
                fontWeight={"bold"}
                component="div"
                style={{
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  height: "3em",
                  lineHeight: "1.5em",
                }}
              >
                {exhibitor?.companyName.value}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {hall.name} | {stand.name}
              </Typography>
            </Box>
          </Paper>
        </Box>
      </CardContent>
    </Card>
  );
};

export default HallView;
