import React from "react";
import vBg from "../../../assets/visitor_bg2.png"; // Path to your image
import { Box } from "@mui/material";
import QRCode from "react-qr-code";
import { BADGE_BG } from "../../../constants/constants";
export const BadgeToPrint = React.forwardRef((props, ref) => {
  const { user, isPreview = false } = props;
  const style = {
    badgeContainer: {
      marginTop: "16px",
      marginLeft: "16px",
      position: "relative",
      width: "10cm",
      height: "14cm",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      color: "black",
      boxSizing: "border-box",
    },
    detailsContainer: {
      textAlign: "center",
      marginTop: "4cm",
    },
    qrCode: {
      marginLeft: "auto",
      marginRight: "auto",
      display: "block",
      marginTop: "0.5cm", // Adjust the margin as needed
      width: "3.5cm", // Adjust size as needed
      height: "3.5cm", // Adjust size as needed
    },
  };

  return (
    <div ref={ref} style={style.badgeContainer}>
      <div style={style.detailsContainer}>
        {isPreview && (
          <img
            src={BADGE_BG}
            alt="Badge Background"
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 1,
            }}
          />
        )}
        <Box
          sx={{
            position: "absolute",
            top: "5cm",
            left: ".2cm",
            right: ".2cm",
            bottom: "1cm",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            zIndex: 2,
          }}
        >
          <Box sx={{ fontWeight: "bold", fontSize: "22px", mt: 6 }}>
            {`${user?.firstName ? user?.firstName?.toUpperCase() : ""} ${
              user?.lastName ? user?.lastName?.toUpperCase() : ""
            }`}
            {`${user?.name ? user?.name?.toUpperCase() : ""}`}
          </Box>
          <Box sx={{ fontWeight: "bold", fontSize: "18px", mt: 1 }}>
            {`${user?.companyName ? user?.companyName?.toUpperCase() : ""}`}
            {`${user?.instituteName ? user?.instituteName?.toUpperCase() : ""}`}
          </Box>
          <Box mt={2}>
            {user.barcode_id && <QRCode value={user.barcode_id} size={130} />}
          </Box>
        </Box>
      </div>
      <div />
    </div>
  );
});
