import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import BackBtn from "../admin/widgets/BackBtn";
import { useQuery } from "../../../utility/utility";
import useCommonGetInit from "../../events/hooks/useCommonGetInit";
import {
  DEFAULT_DP,
  EXHIBITOR_PLACEHOLDER,
  EXHIBITOR_PLACEHOLDER2,
  USER,
  VISITOR,
} from "../../../constants/constants";
import MuPb from "../../../widgets/MuPb";
import CommonItemCard from "../../../widgets/CommonItemCard";

const MyFavorites = () => {
  const query = useQuery();
  const id = query.get("id");
  const { isLoading, isError, error, data } = useCommonGetInit(
    `${USER}/get-favorites?id=${id}&`
  );

  if (isLoading) {
    return <MuPb />;
  }

  if (isError) {
    return <div>Error: {error.message}</div>; // Display error message if there is an error
  }

  return (
    <Box mt={1} ml={2}>
      <BackBtn />
      <Typography variant="h4" component="h2" gutterBottom>
        My Favorites
      </Typography>
      <Box ml={1}>
        <Grid container spacing={2}>
          {data.map((user) => (
            <CommonItemCard item={user?.user_info} />
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default MyFavorites;
