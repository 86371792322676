import React from "react";
import {
  Box,
  Typography,
  Avatar,
  Grid,
  Alert,
  IconButton,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import zIndex from "@mui/material/styles/zIndex";
import EventIcon from "@mui/icons-material/Event";
import ChatIcon from "@mui/icons-material/Chat";

const ExhibitorView = ({ item, onClose }) => {
  if (!item) {
    return <Alert severity="warning">Not found!</Alert>;
  }

  const sendFlutter = (params) => {
    window.FlutterChannel?.postMessage(JSON.stringify(params));
  };
  const style = {
    position: "absolute",
    zIndex: 200,
    width: "90vw",
    maxWidth: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "8px",
    p: 2,
  };

  const labelStyle = {
    fontWeight: "bold",
    color: "primary.main",
  };

  const valueStyle = {
    marginLeft: "8px",
    color: "text.secondary",
  };

  const buttonStyle = {
    marginTop: "16px",
    marginRight: "8px",
  };

  return (
    <Box sx={style}>
      <Grid container spacing={2}>
        <Grid item xs={12} display="flex" justifyContent="flex-end">
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} sm={4} display="flex" justifyContent="center">
          <Avatar
            alt={item.companyName?.value}
            src={item.profile_image}
            sx={{ width: 100, height: 100 }}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography variant="h6" fontWeight={600}>
            {item.companyName?.value}
          </Typography>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Button
              size="small"
              startIcon={<EventIcon />}
              variant="outlined"
              color="primary"
              sx={buttonStyle}
              onClick={() =>
                sendFlutter({
                  type: "meeting",
                  un: item.username,
                  name: item.companyName.value,
                  profile_image: item.profile_image,
                })
              }
            >
              Schedule Meeting
            </Button>
            <Button
              onClick={() =>
                sendFlutter({
                  type: "chat",
                  un: item.username,
                  name: item.companyName.value,
                  profile_image: item.profile_image,
                })
              }
              size="small"
              startIcon={<ChatIcon />}
              variant="outlined"
              color="secondary"
              sx={buttonStyle}
            >
              Chat with Us
            </Button>
          </Box>
          <Typography sx={{ mt: 2 }}>
            <span style={labelStyle}>We are:</span>
            <span style={valueStyle}>{item.weAre?.value}</span>
          </Typography>
          <Typography sx={{ mt: 1 }}>
            <span style={labelStyle}>Company Email:</span>
            <span style={valueStyle}>{item.companyEmail?.value}</span>
          </Typography>
          <Typography sx={{ mt: 1 }}>
            <span style={labelStyle}>Contact Person:</span>
            <span style={valueStyle}>
              {item.contactFirstName?.value} {item.contactLastName?.value},{" "}
              {item.contactDesignation?.value}
            </span>
          </Typography>
          <Typography sx={{ mt: 1 }}>
            <span style={labelStyle}>Contact Email:</span>
            <span style={valueStyle}>{item.contactEmail?.value}</span>
          </Typography>
          <Typography sx={{ mt: 1 }}>
            <span style={labelStyle}>Mobile:</span>
            <span style={valueStyle}>{item.mobile?.value}</span>
          </Typography>
          <Typography sx={{ mt: 1 }}>
            <span style={labelStyle}>Address:</span>
            <span style={valueStyle}>
              {item.country?.value?.name}, {item.state?.value?.name},{" "}
              {item.address1?.value}
            </span>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ExhibitorView;
