import React, { useState } from "react";
import ExhibitorCard from "../../../../widgets/ExhibitorCard";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Typography,
} from "@mui/material";
import useExhibitors from "./hooks/useExhibitors";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../../../widgets/searchBar";
import AlphabetList from "./AlphabetList";
import CountryAutoComplete from "../../../../widgets2024/CountryAutoComplete";
import { USER } from "../../../../constants/constants";
import useCommonGetInit from "../../../events/hooks/useCommonGetInit";
import CustomCircularP from "../../../../widgets/CustomCircularP";

const Exhibitors = () => {
  const [page, setPage] = useState(1);
  const [sq, setSq] = useState("");
  const [selectedHall, setSelectedHall] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedLetter, setSelectedLetter] = useState("");
  const { data, isLoading, isError, error } = useCommonGetInit(
    `${USER}/get-exhibitors?page=${page}&sq=${sq}&hall=${selectedHall}&country=${selectedCountry}&letter=${selectedLetter}&`
  ); // Fetch visitors for page 1

  const onPageChange = (e, page) => {
    setPage(page);
  };
  const onItemSearch = (e) => {
    setSq(e.target.value);
  };
  const handleSelectLetter = (letter) => {
    if (selectedLetter == letter) {
      setSelectedLetter("");
    } else {
      setSelectedLetter(letter);
    }
  };
  const onHallFilter = (e) => {
    setSelectedHall(e.target.value);
  };
  const onCountrySelect = (e, newV) => {
    setSelectedCountry(newV ? newV?.name : "");
  };
  return (
    <div>
      <CustomCircularP show={isLoading} />
      <div>
        <Grid
          pl={2}
          pr={2}
          mt={2}
          mb={2}
          gap={2}
          container
          justifyContent="flex-start" // Align items to the start
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h5" fontWeight={"bold"}>
              Exhibitors
            </Typography>
          </Grid>
          <Grid item>
            <SearchBar
              onSearch={onItemSearch}
              label="Search Exhibitors"
              size="7px"
              boxAlign="inline-flex"
              mystyle={{
                display: "inline-flex",
                backgroundColor: "white",
                width: "276px",
              }}
              cstyle={{ flex: 1 }}
            />
          </Grid>
          {/* Adding a spacer item that grows to push subsequent items to the right */}
          <Grid item xs style={{ flexGrow: 1 }}></Grid>
          <Grid item>
            <Box>
              <Box minWidth={164}>
                <CountryAutoComplete
                  onChange={onCountrySelect}
                  size="small"
                  label="Filter by country"
                />
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <FormControl size="small" sx={{ minWidth: "150px" }}>
              <InputLabel id="demo-simple-select-label">
                Filter by Hall
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedHall}
                label="Filter by Hall"
                onChange={onHallFilter}
              >
                <MenuItem value={""}>All</MenuItem>
                <MenuItem value={"Hall 1"}>Hall 1</MenuItem>
                <MenuItem value={"Hall 2"}>Hall 2</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Box>
          <AlphabetList onSelectLetter={handleSelectLetter} />
        </Box>
        {data?.data?.map((exh, i) => (
          <ExhibitorCard key={i} item={exh} />
        ))}
        <Box mt={2} mb={2} justifyContent={"center"} display={"flex"}>
          <Pagination
            count={Math.ceil(data?.totalItems / 30)}
            page={page}
            onChange={onPageChange}
            color="primary"
          />
        </Box>
      </div>
    </div>
  );
};

export default Exhibitors;
