import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  CssBaseline,
  CircularProgress,
  Box,
} from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import { API_ENDPOINT, USER } from "../../constants/constants";
import imtexLogo from "../../assets/pmtx_header_reg.webp";
import useCommonGetInit from "../../components/events/hooks/useCommonGetInit";
import { useCommonPost } from "../../components/events/hooks/useCommonPost";

const SetPasswordActivationPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { code } = params;
  const { isLoading, isError, error, data } = useCommonGetInit(
    `${USER}/check-link?tk=${code}&`
  );
  const {
    doAction,
    isLoading: isSetLoading,
    isError: isSetError,
    error: setError,
    data: setData,
  } = useCommonPost();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [isPset, setPset] = useState(false);

  const [isValid, setIsValid] = useState(true);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!password.trim() || !confirmPassword.trim()) {
      toast.error("Password fields cannot be empty.");
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Passwords don't match.");
      return;
    }
    doAction({
      postData: {
        token: code,
        password: password,
      },
      path: `${USER}/set-password?`,
    });
  };

  useEffect(() => {
    if (setData) {
      //saveUser(data.data);
      //navigate(`/${data.data.role}`);
      setPset(true);
    }
    if (isSetError) {
      toast.error("Some error occurred");
    }
  }, [setData, isSetError, navigate]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh" // Take up the full viewport height
      >
        <CircularProgress />
      </Box>
    );
  }
  if (isError) {
    return (
      <Container component="main" maxWidth="xs">
        <Typography variant="h4" align="center" color="error">
          Invalid Link!
        </Typography>
      </Container>
    );
  }
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Container component="main" maxWidth="md" sx={{ margin: "16px auto" }}>
        <img
          src={imtexLogo}
          alt=""
          style={{
            display: "block",
            margin: "10px auto",
            width: "100%",
            maxWidth: "500px",
          }}
        />
        <Typography variant="h5" sx={{ textAlign: "center" }}>
          Set New Password
        </Typography>
      </Container>
      {!isPset ? (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div>
            <form onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="New Password"
                type="password"
                name="password"
                autoComplete="new-password"
                autoFocus
                value={password}
                onChange={handlePasswordChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Confirm Password"
                type="password"
                name="confirmPassword"
                autoComplete="new-password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
              />
              <LoadingButton
                loading={isSetLoading}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Set Password
              </LoadingButton>

              {/* <p>This link expires in 5 minutes</p> */}
            </form>
          </div>
        </Container>
      ) : (
        <div style={{ textAlign: "center" }}>
          <h4 color="green">Password set Successfully</h4>
          <p>
            You can now login to the app <a href="/event/login">here</a>
          </p>
        </div>
      )}
    </div>
  );
};

export default SetPasswordActivationPage;
