import React from "react";
import "./ExhibitorCard.css";
import { IconButton } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import StarIcon from "@mui/icons-material/Star";
import ForumIcon from "@mui/icons-material/Forum";
import { DEFAULT_DP } from "../constants/constants";
import EastIcon from "@mui/icons-material/East";
import { Link } from "react-router-dom";
import { getUser } from "../utility/utility";
const VisitorCard = ({ item }) => {
  return (
    <div className="exhibitor-card-c visitor-card">
      <img
        src={item.profile_image ? item.profile_image : DEFAULT_DP}
        style={{
          width: "90px",
          borderRadius: "50%",
          display: "block",
          margin: "auto",
          border: "1px solid #fdca48",
        }}
      />
      <div style={{ margin: "12px 0", textAlign: "center" }}>
        <div>
          <div
            style={{
              fontWeight: 600,
              userSelect: "none",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {item.firstName} {item.lastName}
          </div>
          <div
            style={{
              userSelect: "none",
              marginBottom: "16px",
              marginTop: "4px",
              color: "#444",
              fontSize: "14px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {item.designation}
          </div>

          <div style={{ display: "flex", margin: "4px 0" }}>
            <div style={{ flex: 1, display: "flex", gap: "12px" }}>
              <Link to={`/${getUser()?.role}/chat?tid=${item.username}`}>
                <IconButton
                  sx={{ backgroundColor: "#dbedff" }}
                  aria-label="message"
                  size="small"
                >
                  <ForumIcon sx={{ color: "#00a2d9" }} fontSize="inherit" />
                </IconButton>
              </Link>
              <Link
                to={`/${getUser()?.role}/meeting-scheduler?tid=${
                  item.username
                }`}
              >
                <IconButton
                  sx={{ backgroundColor: "#ffead2" }}
                  aria-label="message"
                  size="small"
                >
                  <CalendarMonthIcon
                    sx={{ color: "#ff981f" }}
                    fontSize="inherit"
                  />
                </IconButton>
              </Link>
              <Link to={`/${getUser()?.role}/favorites?id=${item.user_id}`}>
                <IconButton
                  sx={{ backgroundColor: "#ffead2" }}
                  aria-label="message"
                  size="small"
                >
                  <StarIcon sx={{ color: "#fb4d24" }} fontSize="inherit" />
                </IconButton>
              </Link>
            </div>
            <Link to={`/${getUser()?.role}/view-profile?id=${item.username}`}>
              <IconButton aria-label="arrow" size="small">
                <EastIcon fontSize="inherit" sx={{ color: "#3cb4e0" }} />
              </IconButton>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisitorCard;
