import React, { useEffect, useState } from "react";
import pgBg from "../../../assets/pmtx_cover.jpg";
import "./ProfileDetails.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Box, Button, IconButton, Stack, Tab, Tabs } from "@mui/material";
import {
  getHeader,
  getMultipartHeader,
  getUrlWithProtocol,
  getUser,
} from "../../../utility/utility";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ForumIcon from "@mui/icons-material/Forum";
import StarRateIcon from "@mui/icons-material/StarRate";
import { Cropper } from "react-cropper";
import { useRef } from "react";
import "cropperjs/dist/cropper.css";
import ApartmentIcon from "@mui/icons-material/Apartment";
import axios from "axios";
import { toast } from "react-toastify";
import PublicIcon from "@mui/icons-material/Public";
import RdIconContainer from "../../../widgets/RdIconContainer";
import EditIcon from "@mui/icons-material/Edit";
import parse from "html-react-parser";
import {
  API_ENDPOINT,
  DEFAULT_DP,
  ICON_TYPE_NON_IMAGE,
  USER,
  VISITOR_API,
} from "../../../constants/constants";
import DashButton from "../../../widgets/DashButton";
import Company from "./profile/Company";
import Professional from "./profile/Professional";
import Categories from "./profile/Categories";
import ProfileContainer from "./profile/ProfileContainer";
import HeaderLabel from "../../../widgets/HeaderLabel";
import RdMyModal from "../../../widgets/myModal/RdMyModal";
import SocialLinks from "./profile/edit/SocialLinks";
import MuPb from "../../../widgets/MuPb";
import EditLookingFor from "./profile/edit/EditLookingFor";
import ImageCropEdit from "./profile/edit/ImageCropEditor";
import { Link } from "react-router-dom";
import useCommonGetInit from "../../events/hooks/useCommonGetInit";
import QRCode from "react-qr-code";
export const PROFILE_EDIT_API = `${API_ENDPOINT}${USER}/update-user`;
const ProfileDetails = ({
  user,
  setReloadProfile,
  setUser,
  isView = false,
}) => {
  const { data, isLoading, isError, error } = useCommonGetInit(
    `${USER}/profile-view?id=${user.username}&`
  );
  const inputImg = useRef();
  const cropperRef = useRef(null);
  const image_selector = useRef();
  const [uploading, setUploading] = useState(false);
  const [image, setImage] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [viewMode, setViewMode] = useState(false);
  const [showSocialEdit, setShowSocialEdit] = useState(false);
  const [showLookingFor, setShowLookingFor] = useState(false);
  const [showImageCrop, setShowImageCrop] = useState(false);
  const [showpImageCrop, setShowpImageCrop] = useState(false);
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  const onInputChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  };
  const cancelCrop = () => {
    setImage(null);
    if (image_selector.current) {
      image_selector.current.value = "";
    }
  };
  const uploadImage = async () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    const base64Image = cropper.getCroppedCanvas().toDataURL();

    // remove prefix 'data:image/png;base64,' from the string
    const base64ImageContent = base64Image.replace(
      /^data:image\/(png|jpg);base64,/,
      ""
    );
    const blob = base64toBlob(base64ImageContent, "image/png");

    let formData = new FormData();
    formData.append("file", blob);

    axios
      .post(
        VISITOR_API + "upload-profile-image",
        formData,
        getMultipartHeader()
      )
      .then((res) => {
        let d = res.data;
        if (d.success) {
          toast("Uploaded Successfully");
          setReloadProfile((prevState) => !prevState);
          cancelCrop();
        }
      });
  };
  const base64toBlob = (base64Data, contentType = "") => {
    contentType = contentType || "";
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }

    return new Blob(byteArrays, { type: contentType });
  };
  const onImgSelect = (event) => {
    const [file] = inputImg.current.files;
    if (file) {
      let fd = new FormData();
      fd.append("image", file);
      setUploading(true);
      axios
        .post(
          API_ENDPOINT + "uploads/upload-image-only",
          fd,
          getMultipartHeader()
        )
        .then((res) => {
          let d = res.data;
          setUploading(false);
          if (d.success) {
            updateProfile(d.message);
            // setProductImage(d.message);
          } else {
            toast(d.message);
          }
        })
        .catch((err) => {
          setUploading(false);
          toast.error(`An error occurred while uploading the image: ${err}`);
        });
    }
  };
  useEffect(() => {
    setViewMode(isView);
  }, [isView]);

  const onEditImageClick = () => {
    //image_selector.current.click();
    setShowpImageCrop(true);
  };

  const onEditBannerClick = () => {
    //inputImg.current.click();
    setShowImageCrop(true);
  };
  const updateProfile = (img) => {
    setUploading(true);
    axios
      .post(
        VISITOR_API + "update-visitor",
        { profile_banner: img },
        getHeader()
      )
      .then((res) => {
        setUploading(false);
        const d = res.data;
        if (d.success) {
          setReloadProfile((prevState) => !prevState);
        }
      })
      .catch((err) => {
        setUploading(false);
        toast.error(`An error occurred while fetching exhibitors: ${err}`);
      });
  };

  const onEditSocialMedia = () => {
    setShowSocialEdit(true);
  };
  const onEditLookingFor = () => {
    setShowLookingFor(true);
  };
  const toggleViewMode = (m) => {
    setViewMode(m);
  };
  if (!user) {
    return <MuPb />;
  }

  return (
    <div
      className="rd-dash-content"
      style={{ maxWidth: "1280px", marginLeft: "auto", marginRight: "auto" }}
    >
      <RdMyModal
        showModal={showSocialEdit}
        setShowModal={setShowSocialEdit}
        modalC={
          <SocialLinks
            profile={user}
            setProfile={setUser}
            setShow={setShowSocialEdit}
          />
        }
      />
      <RdMyModal
        showModal={showLookingFor}
        setShowModal={setShowLookingFor}
        modalC={
          <EditLookingFor
            profile={user}
            setProfile={setUser}
            setShow={setShowLookingFor}
          />
        }
      />
      <RdMyModal
        showModal={showImageCrop}
        setShowModal={setShowImageCrop}
        modalC={
          <ImageCropEdit
            profile={user}
            setProfile={setUser}
            show={showImageCrop}
            setShow={setShowImageCrop}
            upload_type="profile_banner"
            aspect_ratio={3}
          />
        }
      />
      <RdMyModal
        showModal={showpImageCrop}
        setShowModal={setShowpImageCrop}
        modalC={
          <ImageCropEdit
            profile={user}
            setProfile={setUser}
            show={showpImageCrop}
            setShow={setShowpImageCrop}
            upload_type="profile_image"
            aspect_ratio={1}
          />
        }
      />
      <input
        onChange={onImgSelect}
        accept="image/*"
        ref={inputImg}
        type="file"
        style={{ display: "none" }}
      />
      <div className="dash-30-70">
        <div className="dash-30">
          <div
            style={{
              borderRadius: "8px",
              boxShadow: "0px 4px 8px rgba(241, 233, 231, 0.5)",
              backgroundColor: "white",
              padding: "10px",
              margin: "0 12px",
            }}
          >
            <div
              style={{
                position: "relative",
                display: "table",
                margin: "auto",
              }}
            >
              <img
                src={
                  user.profile_image !== undefined
                    ? user.profile_image
                    : DEFAULT_DP
                }
                alt="profile"
                style={{
                  width: "90px",
                  height: "90px",
                  borderRadius: "50%",
                  border: "1px solid #b68337",
                  cursor: "pointer",
                }}
              />
              {!viewMode && (
                <RdIconContainer
                  icon={{
                    icon: <EditIcon sx={{ color: "#777" }} />,
                    type: ICON_TYPE_NON_IMAGE,
                    desc: "edit icon",
                    show: true,
                  }}
                  bg="#ddd"
                  hoverBg="#eee"
                  myStyle={{
                    position: "absolute",
                    bottom: 0,
                    right: "-32px",
                  }}
                  onClick={onEditImageClick}
                />
              )}
            </div>
            <div
              style={{
                fontWeight: 600,
                marginTop: "10px",
                textAlign: "center",
                color: "#004490",
              }}
            >
              {user.firstName} {user.lastName}
            </div>
            <div
              style={{ textAlign: "center", fontSize: "15px", color: "#777" }}
            >
              {user.designation}
            </div>

            {!isView ? (
              <div
                style={{
                  display: "flex",
                  margin: "16px 0 10px 0",
                  gap: "12px",
                  justifyContent: "center",
                }}
              >
                <DashButton
                  label="Preview Profile"
                  color="#d17a4e"
                  hoverColor="#b55c28"
                  onClick={() => toggleViewMode(true)}
                  bgColor="#ffead2"
                  hoverBgColor="#ffbca5"
                  myStyle={{ width: "120px" }}
                />
                <DashButton
                  label="Update Profile"
                  onClick={() => toggleViewMode(false)}
                  color="rgb(47 146 188)"
                  hoverColor="rgb(21 80 106)"
                  bgColor="rgb(229 241 253)"
                  hoverBgColor="rgb(198 217 236)"
                  myStyle={{ width: "120px" }}
                />
              </div>
            ) : (
              <Stack justifyContent={"center"} mt={2} gap={2} direction={"row"}>
                <Link to={`/${getUser()?.role}/chat?tid=${user.username}`}>
                  <Button
                    size="small"
                    sx={{
                      fontSize: "11px",
                      backgroundColor: "#dbedff",
                      color: "#35b0e1",
                      textTransform: "none",
                      borderRadius: "20px",
                      padding: "2px 10px",
                      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                      "&:hover": {
                        backgroundColor: "#c2d1f0",
                        color: "#1a94b7",
                        boxShadow: "0 6px 8px rgba(0, 0, 0, 0.1)",
                      },
                    }}
                    className="chat-mui-btn"
                    variant="contained"
                    startIcon={<ForumIcon />}
                  >
                    Message
                  </Button>
                </Link>
                <Link
                  to={`/${getUser()?.role}/meeting-scheduler?tid=${
                    user.username
                  }`}
                >
                  <Button
                    size="small"
                    variant="contained"
                    startIcon={
                      <CalendarMonthIcon style={{ color: "#ffa43d" }} />
                    }
                    sx={{
                      fontSize: "11px",
                      backgroundColor: "#ffead2",
                      color: "#ffa43d",
                      textTransform: "none",
                      borderRadius: "20px",
                      padding: "2px 10px",
                      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                      "&:hover": {
                        backgroundColor: "#fddbb1",
                        color: "#ff8c00",
                        boxShadow: "0 6px 8px rgba(0, 0, 0, 0.1)",
                      },
                    }}
                  >
                    Meeting
                  </Button>
                </Link>
                <Link to={`/${getUser()?.role}/favorites?id=${user.user_id}`}>
                  <Button
                    size="small"
                    variant="contained"
                    startIcon={<StarRateIcon style={{ color: "#fb4d24" }} />}
                    sx={{
                      fontSize: "11px",
                      backgroundColor: "#ffe4de",
                      color: "#fb4d24",
                      borderRadius: "20px",
                      textTransform: "none",
                      padding: "2px 10px",
                      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                      "&:hover": {
                        backgroundColor: "#ffc9b8",
                        color: "#e4411a",
                        boxShadow: "0 6px 8px rgba(0, 0, 0, 0.1)",
                      },
                    }}
                  >
                    Favorite
                  </Button>
                </Link>
              </Stack>
            )}
          </div>
          <div
            style={{
              borderRadius: "8px",
              boxShadow: "0px 4px 8px rgba(241, 233, 231, 0.5)",
              backgroundColor: "white",
              padding: "10px 16px",
              margin: "12px",
            }}
          >
            <div style={{ fontWeight: 600, color: "#004490" }}>
              Company Information
            </div>
            <div style={{ marginTop: "8px" }}>
              <div style={{ display: "flex", marginTop: "10px" }}>
                <ApartmentIcon fontSize="17px" sx={{ color: "#777" }} />
                <div
                  style={{
                    marginLeft: "8px",
                    color: "#777",
                    fontWeight: 600,
                    fontSize: "14px",
                    alignSelf: "center",
                  }}
                >
                  {user.companyName}
                </div>
              </div>

              <div style={{ display: "flex", marginTop: "10px" }}>
                <PublicIcon fontSize="17px" sx={{ color: "#777" }} />
                <div style={{ marginLeft: "8px", alignSelf: "center" }}>
                  {user.website !== "" ? (
                    <a href={getUrlWithProtocol(user.website)} target="_blank">
                      {user.website}
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <br />
            <HeaderLabel
              label={"Social Media"}
              showEdit={!viewMode}
              onEdit={onEditSocialMedia}
            />
            <div style={{ display: "flex", marginTop: "4px" }}>
              {user.fbCheck && (
                <a href={user.fbLink} target="_blank">
                  <IconButton>
                    <FacebookIcon style={{ color: "blue" }} />
                  </IconButton>
                </a>
              )}
              {user.instaCheck && (
                <a href={user.instaLink} target="_blank">
                  <IconButton>
                    <InstagramIcon style={{ color: "#E1306C" }} />
                  </IconButton>
                </a>
              )}
              {user.twitterCheck && (
                <a href={user.twitterLink} target="_blank">
                  <IconButton>
                    <TwitterIcon style={{ color: "#2867B2" }} />
                  </IconButton>
                </a>
              )}
              {user.linkedInCheck && (
                <a href={user.linkedInLink} target="_blank">
                  <IconButton>
                    <LinkedInIcon style={{ color: "#2867B2" }} />
                  </IconButton>
                </a>
              )}
            </div>
          </div>
          <Box>
            <center>
              <QRCode value={user.barcode_id} size={130} />
            </center>
          </Box>
          <div style={{ margin: "12px" }}>
            {/* <div
              style={{
                display: "flex",
                backgroundColor: "#09abd9",
                padding: "8px",
                marginTop: "20px",
                borderRadius: "8px",
              }}
            >
              <div
                style={{
                  fontWeight: 600,
                  color: "white",
                  flex: 1,
                  alignSelf: "center",
                }}
              >
                I am Looking for?
              </div>
              {!viewMode && <EditBtn onEdit={onEditLookingFor} />}
            </div> */}
            <div
              style={{
                marginTop: "8px",
                boxShadow: "rgba(241, 233, 231, 0.5) 0px 4px 8px",
                padding: "10px 16px",
                borderRadius: "8px",
                backgroundColor: "white",
              }}
            >
              <HeaderLabel
                label={"I am Looking for?"}
                showEdit={!viewMode}
                onEdit={onEditLookingFor}
              />
              {user.lookingFor && parse(user.lookingFor)}
            </div>
          </div>
          {/* <p>
            <QRCode value={user.barcode_id} height={100} />
          </p> */}
        </div>
        <div className="dash-70">
          <div
            style={{
              position: "relative",
              height: "172px",
              backgroundImage: `url(${
                user.profile_banner === undefined || user.profile_banner === ""
                  ? pgBg
                  : user.profile_banner
              })`,
              backgroundSize: "cover",
              backgroundPosition: "center", // added this line
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              backgroundRepeat: "no-repeat",
            }}
          >
            {!viewMode && (
              <RdIconContainer
                icon={{
                  icon: <EditIcon sx={{ color: "#777" }} />,
                  type: ICON_TYPE_NON_IMAGE,
                  desc: "edit icon banner",
                  show: true,
                }}
                bg="#ddd"
                hoverBg="#eee"
                myStyle={{ position: "absolute", top: 0, right: "0" }}
                onClick={onEditBannerClick}
              />
            )}
          </div>
          <div>
            <input
              ref={image_selector}
              style={{ display: "none" }}
              type="file"
              onChange={onInputChange}
            />
            {image != null ? (
              <div>
                <Cropper
                  src={image}
                  style={{ height: 400, width: "100%" }}
                  initialAspectRatio={1}
                  preview=".img-preview"
                  ref={cropperRef}
                />
                <button className="rd-custom-button" onClick={uploadImage}>
                  Upload
                </button>
                <button className="rd-custom-button" onClick={cancelCrop}>
                  Cancel
                </button>
              </div>
            ) : null}
          </div>
          <Box
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              boxShadow: "0px 5px 5px 0px rgba(0,0,0,0.1)",
            }}
          >
            <Tabs
              value={currentTab}
              variant="scrollable"
              scrollButtons={false}
              onChange={handleTabChange}
              TabIndicatorProps={{ style: { backgroundColor: "#f4741f" } }}
            >
              <Tab label="About" sx={{ textTransform: "none" }} />
              <Tab label="Company Information" sx={{ textTransform: "none" }} />
              <Tab
                label="Professional Information"
                sx={{ textTransform: "none" }}
              />
              <Tab label="Product Categories" sx={{ textTransform: "none" }} />
            </Tabs>
          </Box>

          <div style={{ marginTop: "12px" }}>
            {currentTab === 0 ? (
              <ProfileContainer
                profile={user}
                isView={viewMode}
                setProfile={setUser}
              />
            ) : currentTab === 1 ? (
              <Company isView={viewMode} profile={user} setProfile={setUser} />
            ) : currentTab === 2 ? (
              <Professional
                isView={viewMode}
                profile={user}
                setProfile={setUser}
              />
            ) : (
              <Categories
                isView={viewMode}
                profile={user}
                setProfile={setUser}
              />
            )}
          </div>

          {/* <PreviewProfile isView={isView} user = {user} setReloadProfile={setReloadProfile}/> */}
        </div>
      </div>
    </div>
  );
};

export default ProfileDetails;
