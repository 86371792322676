import React from "react";
import parse from "html-react-parser";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Box, Button, Chip, Typography } from "@mui/material";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import ForumIcon from "@mui/icons-material/Forum";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Link } from "react-router-dom";
import { getUser } from "../../../utility/utility";
const ProductDetail = ({ product }) => {
  return (
    <div>
      <div className="product-info-c">
        <div className="graphics-area">
          <div style={{ padding: "4px", borderRadius: "8px" }}>
            <img
              style={{ width: "100%", display: "block", borderRadius: "8px" }}
              src={product.images[0]}
              alt={product.name}
            />
          </div>
          <div>
            <div
              style={{
                padding: "4px",
                borderRadius: "8px",
                marginTop: "16px",
                maxWidth: "430px",
              }}
            >
              {product.productVideo &&
                (product.productVideo.selection === "embed" ? (
                  <div className="responsive-content">
                    {parse(product.productVideo.content)}
                  </div>
                ) : (
                  <div className="responsive-content">
                    <video controls>
                      <source
                        src={product.productVideo.content}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="text-area">
          <div style={{ fontWeight: 600, fontSize: "20px" }}>
            {product.name}
          </div>

          <div style={{ marginTop: "12px", fontWeight: 600, color: "#777" }}>
            {product.exhibitor?.companyName?.value}
          </div>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <LocationOnIcon fontSize="17px" sx={{ color: "#888" }} />

            <Link to={`/hall-view/${product.exhibitor?.hallNumber?.value}`}>
              <div
                style={{
                  marginLeft: "8px",
                  color: "#777",
                  fontWeight: 600,
                  fontSize: "14px",
                  alignSelf: "center",
                }}
              >
                {product.exhibitor?.hallNumber?.value} /{" "}
                {product.exhibitor?.standNumber?.value}
              </div>
            </Link>
          </div>
          <Box display="flex" mt={2}>
            <Link
              to={`/${getUser()?.role}/meeting-scheduler?tid=${
                product?.exhibitor?.username
              }`}
            >
              <Button
                color="primary" // This makes the text and the border color as #09abd9
                sx={{
                  textTransform: "none",
                  marginRight: 2,
                }}
                size="small"
                variant="outlined"
                startIcon={<MeetingRoomIcon />}
              >
                Meet
              </Button>
            </Link>
            <Link
              to={`/${getUser()?.role}/chat?tid=${
                product?.exhibitor?.username
              }`}
            >
              <Button
                color="primary"
                sx={{
                  textTransform: "none",
                }}
                size="small"
                variant="outlined"
                startIcon={<ForumIcon />}
              >
                Message
              </Button>
            </Link>
          </Box>
          <Box sx={{ fontWeight: 600, mt: 2, fontSize: "18px" }}>
            Product Info
          </Box>
          <div>{parse(product.description)}</div>

          <Box display={"flex"} mt={2}>
            <Box sx={{ fontWeight: 600, fontSize: "18px" }}>Assign to:</Box>
            <Box ml={2}>{parse(product.assignTo)}</Box>
          </Box>

          {product.brochure && (
            <Button
              size="small"
              sx={{ mt: 2, mb: 2 }}
              variant="outlined"
              startIcon={<PictureAsPdfIcon color="error" />}
              component="a" // Use <a> tag as the root component of the Button
              href={product.brochure} // Link to the PDF
              target="_blank" // Open in a new tab
              rel="noopener noreferrer" // For security reasons when using target="_blank"
            >
              Product Brochure
            </Button>
          )}

          <Box sx={{ fontWeight: 600, fontSize: "18px", mb: 1 }}>
            Categories
          </Box>
          <Box sx={{ color: "#444", mt: 1, mb: 1 }}>
            {Object.keys(product.selectedCategories || {}).map(
              (categoryName, i) => (
                <Box key={i} sx={{ marginBottom: "16px" }}>
                  <Typography fontSize={"14px"} fontWeight={600}>
                    {categoryName}:
                  </Typography>
                  {product.selectedCategories[categoryName].map(
                    (item, index) => (
                      <Chip
                        key={index}
                        label={item}
                        sx={{
                          bgcolor: "rgb(234 234 234)",
                          "&:hover": {
                            bgcolor: "rgb(213 217 211)",
                          },
                          mr: 1, // Spacing between chips
                          mb: 1, // Spacing for wrap
                        }}
                      />
                    )
                  )}
                </Box>
              )
            )}
          </Box>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
