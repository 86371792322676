import React, { useEffect, useState } from "react";
import HeaderLabel from "../../../../widgets/HeaderLabel";
import AddBtn from "../../../../widgets/actionBtn/addBtn";
import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import RdMyModal from "../../../../widgets/myModal/RdMyModal";
import CategoriesEdit from "./edit/CategoriesEdit";
import SimpleYesNoDialog from "../../../../widgets/SimpleYesNoDialog";
import { toast } from "react-toastify";
import { getHeader } from "../../../../utility/utility";
import axios from "axios";
import { PROFILE_EDIT_API } from "../ProfileDetails";

const Categories = ({ profile, isView = false, setProfile }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [curCat, setCurCat] = useState("");
  const onCategoryDelete = (itemToDelete) => {
    setCurCat(itemToDelete);
    setShowDelete(true);
  };
  const onEdit = () => {
    setShowEdit(true);
  };
  const onYesDelete = () => {
    setShowDelete(false);
    const updatedCategories = { ...profile.selectedCategories };
    updatedCategories[curCat.categoryName] = updatedCategories[
      curCat.categoryName
    ].filter((item) => item !== curCat.itemToDelete);

    setDeleting(true);
    axios
      .post(
        PROFILE_EDIT_API,
        {
          selectedCategories: {
            label: "Product Categories",
            value: updatedCategories,
          },
        },
        getHeader()
      )
      .then((res) => {
        setDeleting(false);
        toast.success(res.data.message);
        setProfile((prevState) => ({
          ...prevState,
          selectedCategories: updatedCategories,
        }));
      })
      .catch((error) => {
        setDeleting(false);
        if (error.response) {
          // The request was made and the server responded with a status code outside the range of 2xx
          console.error("Error Data:", error.response.data);
          console.error("Error Status:", error.response.status);
          console.error("Error Headers:", error.response.headers);
          toast.error(
            `Error: ${error.response.data.message || "Something went wrong"}`
          );
        } else if (error.request) {
          // The request was made but no response was received
          console.error("Error Request:", error.request);
          toast.error("No response from server. Please try again later.");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error", error.message);
          toast.error("An error occurred. Please try again.");
        }
      });
  };
  return (
    <div className="profile-info-c">
      {deleting && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center", // center horizontally
            alignItems: "center", // center vertically
            height: "100vh", // take full viewport height
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <div style={{ display: "flex" }}>
        <RdMyModal
          showModal={showEdit}
          setShowModal={setShowEdit}
          modalC={
            <CategoriesEdit
              profile={profile}
              setProfile={setProfile}
              setShow={setShowEdit}
            />
          }
        />
        <SimpleYesNoDialog
          title="Please Confirm!"
          message="Are you sure you want to delete this?"
          onYes={onYesDelete}
          show={showDelete}
          setShow={setShowDelete}
        />
        <HeaderLabel
          onEdit={null}
          showEdit={false}
          label={"Product Categories"}
        />
        <div style={{ flex: 1 }}></div>
        {!isView && <AddBtn onClick={onEdit} label={"Add Categories"} />}
      </div>

      <div>
        <Box>
          {Object.keys(profile.selectedCategories).map((categoryName, i) => (
            <Box key={i} sx={{ marginBottom: "16px" }}>
              <Typography fontSize={"14px"} fontWeight={600}>
                {categoryName}:
              </Typography>
              {profile.selectedCategories[categoryName].map((item, index) => (
                <Chip
                  key={index}
                  label={item}
                  sx={{
                    bgcolor: "rgb(234 234 234)",
                    "&:hover": {
                      bgcolor: "rgb(213 217 211)",
                    },
                    mr: 1, // Spacing between chips
                    mb: 1, // Spacing for wrap
                  }}
                  onDelete={
                    !isView
                      ? () =>
                          onCategoryDelete({
                            categoryName,
                            itemToDelete: item,
                          })
                      : undefined
                  }
                  deleteIcon={
                    <IconButton sx={{ padding: "2px" }} size="small">
                      <CancelIcon />
                    </IconButton>
                  }
                />
              ))}
            </Box>
          ))}
        </Box>
      </div>
    </div>
  );
};

export default Categories;
