import React, { useEffect, useRef, useState } from "react";
import { Grid, Typography, Box, CardContent, Card, List } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Group, Image, Layer, Line, Rect, Stage } from "react-konva";
import useFloorPlan from "../hooks/useFloorPlan";
import MuPb from "../../../widgets/MuPb";
import _ from "lodash";
import { getHoverColor, hexToRGBA } from "../../../utility/utility";
import { TRANSPARENCY } from "../../../constants/constants";
import { CONTAINER_HEIGHT } from "../editor/AddHall";
import { CONTAINER_WIDTH } from "./HallView";

function GeneralView() {
  const { data, isLoading, isError, error, refetchFloorPlan } = useFloorPlan(1);
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [image, setImage] = useState(null);
  const [stageScale, setStageScale] = useState(1);
  const [stagePos, setStagePos] = useState({ x: 0, y: 0 });
  const [stageX, setStageX] = useState(0);
  const [stageY, setStageY] = useState(0);
  const [newHalls, setNewHalls] = useState();

  const stageRef = useRef(null);
  const [initialDistance, setInitialDistance] = useState(null);
  const [lastScale, setLastScale] = useState(1);

  const getDistance = (touch1, touch2) => {
    const dx = touch1.clientX - touch2.clientX;
    const dy = touch1.clientY - touch2.clientY;
    return Math.sqrt(dx * dx + dy * dy);
  };

  const handleTouchStart = (e) => {
    if (e.evt.touches.length === 2) {
      const distance = getDistance(e.evt.touches[0], e.evt.touches[1]);
      setInitialDistance(distance);
    }
  };

  const handleTouchMove = (e) => {
    if (e.evt.touches.length === 2) {
      const newDistance = getDistance(e.evt.touches[0], e.evt.touches[1]);
      const scale = newDistance / initialDistance;

      if (initialDistance) {
        const stage = stageRef.current;
        const newScale = scale * lastScale;

        stage.scaleX(newScale);
        stage.scaleY(newScale);
        stage.batchDraw();
      }
    }
  };

  const handleTouchEnd = () => {
    const stage = stageRef.current;
    setLastScale(stage.scaleX());
    setInitialDistance(null);
  };

  const handleWheel = (e) => {
    e.evt.preventDefault();

    const scaleBy = 1.05;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();
    const pointer = stage.getPointerPosition();

    // Calculate the new scale
    const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;

    // Update the stage scale
    setStageScale(newScale);

    // Calculate the new position
    const newPos = {
      x: pointer.x - ((pointer.x - stage.x()) * newScale) / oldScale,
      y: pointer.y - ((pointer.y - stage.y()) * newScale) / oldScale,
    };

    setStagePos(newPos); // Update the stage position
    setStageX(newPos.x); // Assuming these are used for something else
    setStageY(newPos.y);
  };

  useEffect(() => {
    refetchFloorPlan();
  }, []);

  useEffect(() => {
    if (data) {
      const img = new window.Image();
      img.src = data.image;

      img.onload = () => {
        setImage({
          img: img,
          width: img.width,
          height: img.height,
        });

        if (containerRef.current) {
          const { width } = containerRef.current.getBoundingClientRect();

          const originalWidth = CONTAINER_WIDTH;
          const originalHeight = CONTAINER_WIDTH * (img.height / img.width);
          const newHeight = width * (img.height / img.width);
          setDimensions({
            width,
            height: newHeight,
          });
          const halls = [...data.halls];
          const scaleX = width / originalWidth;
          const scaleY = newHeight / originalHeight;
          halls.forEach((hall) => {
            if (hall.shape.type === "Polygon") {
              // Scale polygon points
              hall.shape.shape = hall.shape.shape.map((point, index) => {
                return index % 2 === 0 ? point * scaleX : point * scaleY;
              });
            } else if (hall.shape.type === "Rectangle") {
              // Scale rectangle properties
              hall.shape.shape.x *= scaleX;
              hall.shape.shape.y *= scaleY;
              hall.shape.shape.width *= scaleX;
              hall.shape.shape.height *= scaleY;
            }
          });
          setNewHalls(halls);
        }
      };
    }
  }, [data]);
  useEffect(() => {
    if (isError) {
      if (error.message === "Not Found") {
        //toast.info("Nothing found");
      } else if (error.message === "Internal Server Error") {
        //toast.error("Some internal error occured");
      } else {
        //toast.error("Some error occured");
      }
    }
  }, [isError]);
  if (isLoading) {
    return <MuPb />;
  }

  return (
    <Box sx={{ maxWidth: "1200px", mt: 1, ml: "auto", mr: "auto" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8}>
          <Card>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  // Adjust as per your needs
                }}
              >
                <Typography variant="h6">General Plan</Typography>
              </Box>
              <Card
                ref={containerRef}
                sx={{
                  height: `${CONTAINER_HEIGHT - 100}px`,
                  overflow: "hidden",
                  position: "relative",
                }}
                variant="outlined"
              >
                {image ? (
                  <Stage
                    ref={stageRef}
                    width={dimensions.width}
                    height={CONTAINER_HEIGHT - 100}
                    scaleX={stageScale}
                    scaleY={stageScale}
                    x={stagePos.x}
                    y={stagePos.y}
                    draggable={true}
                    onWheel={handleWheel}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                  >
                    <Layer>
                      <Image
                        image={image.img}
                        width={dimensions.width}
                        height={dimensions.height}
                        x={(dimensions.width - dimensions.width) / 2} // Center horizontally
                        y={(CONTAINER_HEIGHT - 226 - dimensions.height) / 2}
                      />
                      {newHalls
                        ?.filter((hall) => hall.shape.type === "Polygon")
                        .map((hall) => (
                          <Group>
                            <Line
                              points={hall.shape.shape}
                              fill={hexToRGBA(hall.color, TRANSPARENCY)}
                              stroke={hexToRGBA(hall.color, TRANSPARENCY)}
                              strokeWidth={hall.strokeWidth}
                              closed={true}
                              onClick={() => {
                                navigate(`/hall-view/${hall.name}`);
                              }}
                              onTap={() => {
                                navigate(`/hall-view/${hall.name}`);
                              }}
                              onMouseEnter={(e) => {
                                const shape = e.target;
                                shape.fill(getHoverColor(hall.color)); // Change fill to a darker blue on hover
                                shape.getLayer().batchDraw(); // Redraw the layer for immediate visual feedback
                              }}
                              onMouseLeave={(e) => {
                                const shape = e.target;
                                shape.fill(hexToRGBA(hall.color, TRANSPARENCY)); // Reset to the original color on mouse leave
                                shape.getLayer().batchDraw(); // Redraw the layer for immediate visual feedback
                              }}
                            />
                            {/* <Text
                              text={hall.name}
                              x={
                                Math.abs(
                                  calculateTextPosition(hall.shape.shape).x
                                ) - 36
                              }
                              y={
                                Math.abs(
                                  calculateTextPosition(hall.shape.shape).y
                                ) - 16
                              }
                              fill={"black"}
                              fontSize={17} // Adjust the font size
                              fontWeight="bold" // Make the text bold
                              shadowColor="white" // Add a shadow for better visibility
                              shadowBlur={2} // Adjust the shadow blur
                              shadowOffsetX={1} // Adjust the shadow X offset
                              shadowOffsetY={1} // Adjust the shadow Y offset
                              shadowOpacity={0.7} // Adjust the shadow opacity
                            /> */}
                          </Group>
                        ))}
                      {newHalls
                        ?.filter((hall) => hall.shape.type === "Rectangle")
                        .map((hall) => (
                          <Group>
                            <Rect
                              x={hall.shape.shape.x}
                              y={hall.shape.shape.y}
                              width={hall.shape.shape.width}
                              height={hall.shape.shape.height}
                              fill={hexToRGBA(hall.color, TRANSPARENCY)}
                              onClick={() => {
                                navigate(`/hall-view/${hall.name}`);
                              }}
                              onTap={() => {
                                navigate(`/hall-view/${hall.name}`);
                              }}
                              onMouseEnter={(e) => {
                                const shape = e.target;
                                shape.fill(getHoverColor(hall.color)); // Change fill to a darker blue on hover
                                shape.getLayer().batchDraw(); // Redraw the layer for immediate visual feedback
                              }}
                              onMouseLeave={(e) => {
                                const shape = e.target;
                                shape.fill(hexToRGBA(hall.color, TRANSPARENCY)); // Reset to the original color on mouse leave
                                shape.getLayer().batchDraw(); // Redraw the layer for immediate visual feedback
                              }}
                            />
                          </Group>
                        ))}
                    </Layer>
                  </Stage>
                ) : data?.image ? (
                  <Box sx={{ p: 3, width: "100%", height: "100%" }}>
                    Loading ...
                  </Box>
                ) : (
                  <Box
                    sx={{
                      p: 3,
                      fontWeight: 600,
                      color: "#ccc",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    Floor Plan not available
                  </Box>
                )}
              </Card>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Card>
            <CardContent>
              <Box>
                <Box
                  sx={{
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    // Adjust as per your needs
                  }}
                >
                  <Typography variant="h6">Hall List</Typography>
                </Box>

                <Typography variant="caption" gutterBottom>
                  EXHIBITION HALLS
                </Typography>

                <List>
                  {data && data.halls.length > 0 ? (
                    data.halls.map((hall, index) => (
                      <Card
                        key={index}
                        variant="outlined"
                        sx={{
                          padding: 1,
                          mt: 1,
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "#f5f5f5", // change this color to suit your design
                          },
                        }}
                        onClick={() => {
                          navigate(`/hall-view/${hall.name}`);
                        }} // Replace with your click handler
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            // Adjust as per your needs
                          }}
                        >
                          <Typography variant="body1">{hall.name}</Typography>
                        </Box>
                      </Card>
                    ))
                  ) : (
                    <Typography
                      variant="h6"
                      sx={{ mt: 3, textAlign: "center" }}
                    >
                      No halls available.
                    </Typography>
                  )}
                </List>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default GeneralView;
