import { Box, Paper, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const TempView = () => {
  useEffect(() => {
    // Find the existing og:image meta tag
    const metaTag = document.querySelector('meta[property="og:image"]');
    const imageUrl =
      "https://s3.ap-south-1.amazonaws.com/expoplanner.in/uploads/IMTEX+2024+(1).png";
    // If the tag exists, update its content attribute
    if (metaTag) {
      metaTag.setAttribute("content", imageUrl);
    } else {
      // If the tag does not exist, create it and append to <head>
      const newMetaTag = document.createElement("meta");
      newMetaTag.setAttribute("property", "og:image");
      newMetaTag.setAttribute("content", imageUrl);
      document.getElementsByTagName("head")[0].appendChild(newMetaTag);
    }
    document.title =
      "IMTEX FORMING 2024, TOOLTECH 2024 & DIGITAL MANUFACTURING 2024";
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh", // Make box fill the screen height
        backgroundColor: "#121212", // Darker background
        color: "#fff", // White text color for better contrast
      }}
    >
      <Paper
        elevation={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          p: 4,
          m: 2, // margin
          backgroundColor: "#fff", // White background for the card
          color: "#000", // Dark text for readability
          borderRadius: "8px", // Card border radius
          minWidth: "300px", // Minimum width
        }}
      >
        <Typography variant="h5" component="h2" gutterBottom color="primary">
          Registration is Closed
        </Typography>
        <Typography variant="body1">
          For latest info visit{" "}
          <a
            href="https://www.imtex.in/"
            style={{ textDecoration: "underline", color: "inherit" }} // Add styling similar to MUI Link
          >
            https://www.imtex.in/
          </a>
        </Typography>
      </Paper>
    </Box>
  );
};

export default TempView;
