import React from "react";
import regLogo from "../../../assets/imtex_header.png";
import mLogo from "../../assets/mlogo.png";
import lLogo from "../../assets/llogo.png";
import headerC from "../../../assets/pmtx_header4.png";
import headerBg from "../../../assets/header_bg.png";
import "./regHeader.css";
import { VISITOR_REG_HEADER } from "../../../constants/constants";

const RegHeader = () => {
  return (
    <a href="https://www.mtx.co.in/" target="_blank">
      <div
        className="newhcontainer"
        style={{
          backgroundImage: `url(${"https://s3.ap-south-1.amazonaws.com/expoplanner.in/PMTX+2024+-+BG.webp"})`,
        }}
      >
        <img
          className="newhheader-image"
          src={VISITOR_REG_HEADER}
          alt="App Header"
        />
      </div>
    </a>
  );
};

export default RegHeader;
