import {
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import useCities from "../../../../../visitorregt/components/previewpage/hooks/useCities";

function CitiesSelect({ state, handleStateChange }) {
  const { data, isLoading, isError, error } = useCities(
    state?.country?.isoCode,
    state?.state?.isoCode
  );

  const handleInputChange = (event) => {
    // Assume cities have a unique 'id' or similar property
    const selectedCity = data.find((city) => city.name === event.target.value);
    handleStateChange(event.target.name, selectedCity);
  };

  if (isLoading) {
    return <LinearProgress />;
  }

  // Determine the 'id' of the currently selected city for the Select's value
  // Adjust this if cities do not have an 'id' but another unique identifier
  const selectedCity = state.city ? state.city.name : "";

  return (
    <FormControl fullWidth size="small">
      <InputLabel id="city-select-label">Select City*</InputLabel>
      <Select
        labelId="city-select-label"
        id="city-select"
        name="city"
        value={selectedCity}
        label="Select City*"
        onChange={handleInputChange}
      >
        {data?.map((city) => (
          <MenuItem key={city.name} value={city.name}>
            {city.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default CitiesSelect;
