import { Alert, Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import RegHeader from "../../visitorregt/components/headers/regHeader";
import Footer from "../footer/footer";
import { LoadingButton } from "@mui/lab";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import axios from "axios";
import { API_ENDPOINT } from "../../constants/constants";
import {
  deleteOnsite,
  isOnsiteActivated,
  toastError,
} from "../../utils2024/utils";
import { toast } from "react-toastify";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { Link } from "react-router-dom";
import ActivateCard from "./ActivateCard";

const ActivateOnsite1 = () => {
  return (
    <Box height="100vh" display="flex" flexDirection="column">
      <RegHeader />
      <Box
        maxWidth="1280px"
        margin="auto"
        flex={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Grid container spacing={2}>
          {[1, 2, 3, 4, 5, 6].map((group) => (
            <Grid item xs={12} sm={6} md={4} key={group}>
              <ActivateCard groupNum={group} />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Footer />
    </Box>
  );
};

export default ActivateOnsite1;
