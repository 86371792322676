import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PUNE_CATEGORIES } from "../../../constants/constants";

function CategoryAccordionFree({ selectedCategories, setSelectedCategories }) {
  const handleSubCategoryChange = (mainCategory, subCategory) => {
    // Retrieve current selections for a main category, or default to an empty array
    const currentSelected = selectedCategories[mainCategory] || [];
    // Determine the new selection state: add if not present, remove if already there
    const newSelected = currentSelected.includes(subCategory)
      ? currentSelected.filter((item) => item !== subCategory) // Remove the sub-category
      : [...currentSelected, subCategory]; // Add the sub-category

    // Update the selected categories state
    setSelectedCategories((prevSelectedCategories) => ({
      ...prevSelectedCategories,
      [mainCategory]: newSelected,
    }));
  };

  return (
    <div>
      {PUNE_CATEGORIES.map((category, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}a-content`}
            id={`panel${index}a-header`}
          >
            <Typography>{category.MainCategory}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl component="fieldset" fullWidth>
              <FormGroup>
                {category.Categories.map((subCategory) => (
                  <FormControlLabel
                    key={subCategory}
                    control={
                      <Checkbox
                        checked={
                          selectedCategories[category.MainCategory]?.includes(
                            subCategory
                          ) || false
                        }
                        onChange={() =>
                          handleSubCategoryChange(
                            category.MainCategory,
                            subCategory
                          )
                        }
                      />
                    }
                    label={subCategory}
                    sx={{
                      ml: 2, // Adds left margin for spacing
                      "& .MuiTypography-root": {
                        // Targets the label's typography
                        fontSize: "0.875rem", // Smaller text size
                      },
                    }}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
export default CategoryAccordionFree;
