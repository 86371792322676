import React, { useRef, useState } from "react";
import { Box, Button, IconButton, Stack } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const AlphabetList = ({ onSelectLetter }) => {
  const alphabet = Array.from({ length: 26 }, (_, i) =>
    String.fromCharCode(65 + i)
  );
  const scrollContainerRef = useRef(null);
  const [selectedLetter, setSelectedLetter] = useState("");

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -100, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 100, behavior: "smooth" });
    }
  };

  const handleSelectLetter = (letter) => {
    if (selectedLetter == letter) {
      setSelectedLetter("");
    } else {
      setSelectedLetter(letter);
    }
    onSelectLetter(letter); // Callback to parent component or additional logic
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        overflow: "hidden",
        maxWidth: 1200,
        ml: "auto",
        mr: "auto",
      }}
    >
      <IconButton onClick={scrollLeft} aria-label="scroll left">
        <ArrowBackIosNewIcon />
      </IconButton>
      <Box
        ref={scrollContainerRef}
        sx={{
          overflow: "auto",
          whiteSpace: "nowrap",
          flexGrow: 1,
        }}
      >
        <Stack direction="row" spacing={1}>
          {alphabet.map((letter) => (
            <Button
              size="small"
              key={letter}
              variant={letter === selectedLetter ? "contained" : "outlined"}
              onClick={() => handleSelectLetter(letter)}
              color={letter === selectedLetter ? "primary" : "inherit"} // Highlight the selected letter
              sx={{ fontWeight: letter === selectedLetter ? 700 : 400 }} // Optional: make the selected letter bold
            >
              {letter}
            </Button>
          ))}
        </Stack>
      </Box>
      <IconButton onClick={scrollRight} aria-label="scroll right">
        <ArrowForwardIosIcon />
      </IconButton>
    </Box>
  );
};

export default AlphabetList;
