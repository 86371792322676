import React, { useState, useRef, useEffect } from "react";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import MessageIcon from "@mui/icons-material/Message";
import {
  Popover,
  List,
  ListItem,
  ListItemText,
  Badge,
  ListItemButton,
  Divider,
  TextField,
  Button,
  Box,
  ListItemIcon,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import HelpIcon from "@mui/icons-material/Help";
import Person2Icon from "@mui/icons-material/Person2";

import {
  APP_HEADER_LOGO,
  HEADER_RIGHT_MESSAGE,
  HEADER_RIGHT_MODE_SETTINGS,
  HEADER_RIGHT_NOTIFICATION,
  HEADER_RIGHT_PROFILE,
  ICON_TYPE_IMAGE,
  ICON_TYPE_NON_IMAGE,
} from "../../../constants/constants";
import RdIconContainer from "../../../widgets/RdIconContainer";
import "./DashHeader.css";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import { Link, useNavigate } from "react-router-dom";
const NewDashHeader = ({
  user,
  onMenuClick,
  notifications = [],
  onHelpDeskPopup,
  profileImg,
  profileLink,
  onLogout,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const anchorEl = useRef(null);
  const [openM, setOpenM] = useState(false);
  const manchorEl = useRef(null);
  const unseenCount = notifications.reduce(
    (count, notification) => count + (!notification.seen ? 1 : 0),
    0
  );

  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const profileMenuAnchor = useRef();

  const [nanchorEl, setnAnchorEl] = useState(null);

  const [profileMenuItems, setProfileMenuItems] = useState([]);

  const [message, setMessage] = useState("");
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleMOpen = () => {
    setOpenM(true);
  };

  const handleMClose = () => {
    setOpenM(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage(""); // Clear the message
    handleMClose();
  };

  useEffect(() => {
    if (profileLink === "/visitor/profile") {
      setProfileMenuItems([
        {
          icon: <Person2Icon fontSize="small" />,
          text: "Profile",
        },
        {
          icon: <LogoutIcon fontSize="small" />,
          text: "Logout",
        },
      ]);
    } else {
      setProfileMenuItems([
        {
          icon: <Person2Icon fontSize="small" />,
          text: "Profile",
        },

        {
          icon: <HelpIcon fontSize="small" />,
          text: "Helpdesk",
        },
        {
          icon: <LogoutIcon fontSize="small" />,
          text: "Logout",
        },
      ]);
    }
  }, [profileLink]);
  const rightIcons = {
    [HEADER_RIGHT_MODE_SETTINGS]: {
      icon: <Brightness4Icon />,
      type: ICON_TYPE_NON_IMAGE,
      desc: "settings",
      show: true,
    },
    [HEADER_RIGHT_NOTIFICATION]: {
      icon: <NotificationsNoneIcon />,
      type: ICON_TYPE_NON_IMAGE,
      desc: "notification",
      show: true,
    },
    [HEADER_RIGHT_MESSAGE]: {
      icon: <MessageIcon />,
      type: ICON_TYPE_NON_IMAGE,
      desc: "message",
      show: false,
    },
    [HEADER_RIGHT_PROFILE]: {
      icon: profileImg,
      type: ICON_TYPE_IMAGE,
      desc: "account icon",
      show: true,
    },
  };
  const handleProfileMenuClick = (itemNo) => {
    switch (itemNo) {
      case "Profile":
        navigate(profileLink);
        setOpenProfileMenu(false);
        break;
      case "Helpdesk":
        onHelpDeskPopup();
        setOpenProfileMenu(false);
        break;
      default:
        onLogout();
        setOpenProfileMenu(false);
        break;
    }
  };

  const onNotifyClick = (e) => {
    setnAnchorEl(e.currentTarget);
  };
  const handlenClose = () => {
    setnAnchorEl(null);
  };
  const onMessageClick = () => {
    handleMOpen();
  };
  const onProfileClick = (e) => {
    setOpenProfileMenu(true);
  };
  //   const sampleNotifications = [
  //     { title: "New comment on your post", subtitle: "" },
  //     { title: "John Doe followed you", subtitle: "5 mins ago" },
  //     { title: "Your order has been shipped", subtitle: "30 mins ago" },
  //     { title: "Update available for your app", subtitle: "1 hour ago" },
  //   ];

  return (
    <div className="expp-dash-header-c">
      <div className="hd-1-item">
        <Link to={`/${user.role}`}>
          <img
            src={APP_HEADER_LOGO}
            alt="Imtex Logo"
            style={{ marginLeft: "16px", cursor: "pointer" }}
          />
        </Link>
      </div>
      <div className="header-menu-btn">
        <IconButton
          onClick={onMenuClick}
          color="primary"
          aria-label="menu"
          size="large"
        >
          <MenuIcon fontSize="inherhit" />
        </IconButton>
      </div>
      <div style={{ flex: 1 }}></div>
      <div className="hd-2-item">
        {/* <RdIconContainer icon={rightIcons[HEADER_RIGHT_MODE_SETTINGS]} onClick={onNotifyClick}/> */}
        <IconButton
          color="primary"
          aria-label="notifications"
          onClick={onNotifyClick}
        >
          <Badge badgeContent={unseenCount} color="secondary">
            <NotificationsNoneIcon />
          </Badge>
        </IconButton>
        <Popover
          open={Boolean(nanchorEl)}
          anchorEl={nanchorEl}
          onClose={handlenClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <List>
            {notifications.map((notification, index) => (
              <div key={notification._id}>
                <ListItemButton component="a" href={notification.clickableLink}>
                  <ListItemText
                    primary={notification.message}
                    secondary={new Date(
                      notification.createdOn
                    ).toLocaleString()}
                  />
                </ListItemButton>
                {index !== notifications.length - 1 && <Divider />}
              </div>
            ))}
          </List>
        </Popover>
        <RdIconContainer
          anyRef={manchorEl}
          icon={rightIcons[HEADER_RIGHT_MESSAGE]}
          onClick={onMessageClick}
        />
        <Popover
          open={openProfileMenu}
          anchorEl={profileMenuAnchor.current}
          onClose={() => setOpenProfileMenu(false)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <List>
            {profileMenuItems.map((item, i) => (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => handleProfileMenuClick(item.text)}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Popover>

        <Popover
          open={openM}
          anchorEl={manchorEl.current}
          onClose={handleMClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Box p={2}>
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Enter your message"
              />
              <Box mt={2}>
                <Button type="submit" variant="contained" color="primary">
                  Send
                </Button>
              </Box>
            </form>
          </Box>
        </Popover>

        <RdIconContainer
          anyRef={profileMenuAnchor}
          icon={rightIcons[HEADER_RIGHT_PROFILE]}
          onClick={onProfileClick}
        />
      </div>
    </div>
  );
};

export default NewDashHeader;
