import React from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import Footer from "../footer/footer";
import RegHeader from "../../visitorregt/components/headers/regHeader";
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { ExpoTheme } from "../../theme/theme";

const OnSiteDashboard = () => {
  const navigate = useNavigate();
  return (
    <ThemeProvider theme={ExpoTheme}>
      <Box
        sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <RegHeader />
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box display={"flex"} gap={4}>
            <Card
              sx={{
                p: 2,
                bgcolor: "#266b83",
                textAlign: "center",
              }}
            >
              <CardContent>
                <Typography
                  sx={{ fontSize: 22 }}
                  textAlign={"center"}
                  color="white"
                  gutterBottom
                >
                  If You Are Already Registered
                </Typography>
                <Box mt={2}>
                  <Button
                    onClick={() => navigate("/scan-badge")}
                    variant="contained"
                  >
                    Scan Your QR Code
                  </Button>
                </Box>
              </CardContent>
            </Card>

            <Card
              sx={{
                p: 2,
                bgcolor: "#972A4C",
                textAlign: "center",
              }}
            >
              <CardContent>
                <Typography
                  sx={{ fontSize: 22 }}
                  textAlign={"center"}
                  color="white"
                  gutterBottom
                >
                  If You Are Not Registered
                </Typography>
                <Box mt={2}>
                  <Button
                    onClick={() => navigate("/spot-registration")}
                    variant="contained"
                  >
                    NEW REGISTRATION
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Box>

          <Button
            sx={{ mt: 2 }}
            variant="contained"
            onClick={() => navigate("/onsite-users")}
            endIcon={<PersonSearchIcon />}
          >
            All Onsite Users
          </Button>
        </Box>
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default OnSiteDashboard;
