import React, { useState } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import RdSideMenu from "../../../../widgets/RdSideMenu";
import { Button } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
const MobileSideMenuList = ({
  menuList,
  showSideMenu = false,
  setShowSideMenu,
  page,
  onLogout,
}) => {
  const ExploreIcon = menuList[1].icon;
  const exploreActive = page === menuList[1].to;
  const [isSublinkVisible, setIsSublinkVisible] = useState(false);

  const toggle = () => {
    setIsSublinkVisible((prev) => !prev);
  };
  const onClose = () => {
    setShowSideMenu(false);
  };
  return (
    <div
      style={{ display: showSideMenu ? "block" : "none" }}
      className="mobile-rd-side-menu"
    >
      <IconButton
        size="small"
        sx={{
          position: "absolute",
          right: "-16px",
          top: "-24px",
          bgcolor: "gray",
          "&:hover": {
            bgcolor: "darkgray", // Change the color on hover
          },
        }}
        edge="end"
        aria-label="close"
        onClick={onClose}
      >
        <CloseIcon fontSize="small" /> {/* Adjust icon size here */}
      </IconButton>

      <RdSideMenu
        to={menuList[0].to}
        active={page === menuList[0].to}
        text={menuList[0].text}
        MenuIcon={menuList[0].icon}
      />
      <div>
        <div
          className={exploreActive ? "rd-menu-button active" : "rd-menu-button"}
          onClick={toggle}
        >
          <ExploreIcon className="side-menu-icon" />
          <div style={{ flex: 1, alignSelf: "center" }}>{menuList[1].text}</div>
        </div>
        <div className={`sublink-con ${isSublinkVisible ? "visible" : ""}`}>
          {menuList[1].sublinks.map((sl, i) => (
            <Link to={sl.link}>
              <Button
                variant="text"
                size="small"
                fullWidth
                startIcon={<ArrowRightIcon style={{ marginLeft: "-4px" }} />}
                className="sublink-item"
                style={{ justifyContent: "flex-start" }}
              >
                {sl.label}
              </Button>
            </Link>
          ))}
        </div>
      </div>
      {menuList.slice(2).map((v, i) => (
        <RdSideMenu
          key={i}
          to={v.to}
          active={page === v.to}
          text={v.text}
          MenuIcon={v.icon}
        />
      ))}
      <div style={{ marginTop: "16px" }}>
        <RdSideMenu
          onLogout={onLogout}
          to=""
          text="Logout"
          MenuIcon={LogoutIcon}
        />
      </div>
    </div>
  );
};

export default MobileSideMenuList;
