import React from "react";
import useCommonGetInit from "../../events/hooks/useCommonGetInit";
import { USER } from "../../../constants/constants";
import { useQuery } from "../../../utility/utility";
import MuPb from "../../../widgets/MuPb";
import { Alert, Box } from "@mui/material";
import BackBtn from "../admin/widgets/BackBtn";
import ProductDetail from "./ProductDetail";

const ProductContainer = () => {
  const query = useQuery();
  const id = query.get("id");
  const { data, isLoading, isError, error } = useCommonGetInit(
    `${USER}/get-product-by-id?id=${id}&`
  );
  if (isLoading) {
    return <MuPb />;
  }
  if (isError) {
    return <Alert severity="error">{error?.message}</Alert>;
  }
  return (
    <Box pl={2} pt={1} pr={2}>
      <BackBtn />
      <Box>
        <ProductDetail product={data} />
      </Box>
    </Box>
  );
};

export default ProductContainer;
