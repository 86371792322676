import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";

const industrySectorsList = [
  "Academia and Technical Institutions",
  "Aerospace / Civil Aviation",
  "Agriculture",
  "Aluminium Extrusion",
  "Auto Components",
  "Automation",
  "Automobile",
  "Biotechnology",
  "Capital Goods",
  "Chemicals",
  "Consumer Durables",
  "Defence and Railway Units",
  "Die & Mould",
  "Earthmoving Equipment",
  "Electrical and Electronics",
  "Embassies / High Commission",
  "Engineering Services",
  "Food Processing and Diary Equipment",
  "General Engineering",
  "Government Department",
  "Healthcare",
  "IT Industry",
  "Industrial Machinery",
  "Industry 4.0",
  "Infrastructure & Earth Moving Equipment",
  "Job Shops",
  "Logistics",
  "Machine Tools & Other Metal working Industries",
  "Maintenance & Services",
  "Material Handling",
  "Measuring Instruments & Gauges",
  "Medical Engineering",
  "Office Equipment and automation",
  "Oil & Gas equipment",
  "Paper Industries & Products",
  "Pharma Equipment",
  "Plant and Machinery",
  "Plastics Processing",
  "Polymers(Rubber Plastic PVC Resin Poly)",
  "Public Sector",
  "Renewable Energy",
  "Research and development organisations",
  "Space and Nuclear",
  "Telecom Equipment",
  "Textile Machinery & Products",
  "Tractors and Farm Equipment",
  "White and Brown Goods Manufacturers",
];

function RdInductrySectory({ state, handleStateChange }) {
  const handleInputChange = (event) => {
    handleStateChange(event.target.name, event.target.value);
  };

  return (
    <FormControl fullWidth size="small">
      <InputLabel id="demo-simple-select-label">Industry sector*</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        name="selectedIndustrySector"
        value={state.selectedIndustrySector}
        label="Industry sector*"
        onChange={handleInputChange}
      >
        {industrySectorsList.sort().map((position) => (
          <MenuItem key={position} value={position}>
            {position}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default RdInductrySectory;
