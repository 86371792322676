import { Box, ThemeProvider } from "@mui/material";
import React from "react";
import HeaderBar from "../widgets2024/HeaderBar";
import { Route, Routes, useNavigate } from "react-router-dom";
import EventsList from "./EventsList";
import AddEvent from "./AddEvent";
import Databases from "./Databases";
import EventApps from "./EventApps";
import OrgProfile from "./OrgProfile";
import OrgLogout from "./OrgLogout";
import { ExpoTheme } from "../theme/theme";
const OrgAdmin = () => {
  const navItems = {
    rootPath: "/organiser",
    barLogo: "https://www.imtex.in/images/imtex-2024.png",
    barName: "Organiser Dashboard",
    profileName: "Ratul Doley",
    leftItems: [
      {
        label: "Add Event",
        path: "/add-event",
        icon: "AddIcon",
      },
    ],
    rightItems: [
      {
        label: "Database",
        icon: "StorageIcon",
        path: "/database",
      },
      {
        label: "Apps",
        icon: "PhoneIphoneIcon",
        path: "/apps",
      },
      {
        label: "Accounts",
        icon: "GroupIcon",
        path: "/accounts",
      },
      {
        label: "Profile",
        icon: "AccountCircle",
        path: "/profile",
      },
      {
        label: "Logout",
        icon: "LogoutIcon",
        path: "/logout",
      },
    ],
  };

  return (
    <Box>
      <HeaderBar navItems={navItems} />
      <Box sx={{ p: 2 }}>
        <Routes>
          <Route path="" element={<EventsList />} />
          <Route path="add-event" element={<AddEvent />} />
          <Route path="databases" element={<Databases />} />
          <Route path="apps" element={<EventApps />} />
          <Route path="profile" element={<OrgProfile />} />
          <Route path="logout" element={<OrgLogout />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default OrgAdmin;
