import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { useTheme } from "@mui/material/styles";
import { toast } from "react-toastify";
import axios from "axios";
import {
  API_ENDPOINT,
  EXHIBITOR_API,
  USER,
} from "../../../constants/constants";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
  getExhibitorHeader,
  getExhibitorMultipartHeader,
  getHeader,
  getMultipartHeader,
} from "../../../utility/utility";
import { LoadingButton } from "@mui/lab";
const Input = styled("input")({
  display: "none",
});

function AddBrochure({ item, onAdded, showAdd, mode = "add" }) {
  const [title, setTitle] = useState("");
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [previewUrl, setPreviewUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (mode === "add") {
      setTitle("");
      setPreviewUrl("");
      setFileName("");
      setFile(null);
    } else {
      setTitle(item.title);
      setPreviewUrl(item.preview);
      setFileName("");
      setFile(null);
    }
  }, [mode]);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0]?.name || "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      toast.error("Please select a PDF file");
      return;
    }
    if (title === "") {
      toast.error("Please enter title");
      return;
    }

    const formData = new FormData();
    formData.append("title", title);
    formData.append("pdf", file);
    setLoading(true);
    try {
      const { data } = await axios.post(
        `${API_ENDPOINT}${USER}/update-brochure`,
        formData,
        getMultipartHeader()
      );
      setLoading(false);
      toast.success(data.message);
      setTitle("");
      setFile(null);
      setTimeout(() => {
        document.location.reload();
      }, 10); // Fixed typo: 5 -> 5000 for 5 seconds
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  return (
    <Container component="main">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 2,
          mb: 2,
        }}
      >
        <Typography component="h1" variant="h5">
          {mode === "add" ? "Add Brochure" : "Update Brochure"}
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ mt: 3, width: "100%" }}
        >
          <TextField
            label="Title"
            variant="outlined"
            fullWidth
            value={title}
            onChange={handleTitleChange}
            margin="normal"
          />

          {mode === "edit" && (
            <Box>
              <Typography fontWeight={600}>Current PDF</Typography>
              <img
                src={item.preview}
                alt=""
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                  maxHeight: "120px",
                  borderRadius: "8px",
                  border: "1px solid #ddd",
                }}
              />
            </Box>
          )}

          <input
            accept="application/pdf"
            id="contained-button-file"
            type="file"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          <label htmlFor="contained-button-file">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                p: 2,
                border: `2px dashed ${theme.palette.divider}`,
                borderRadius: "10px",
                cursor: "pointer",
                mt: 2,
                ":hover": {
                  backgroundColor: theme.palette.action.hover,
                },
              }}
            >
              <CloudUploadIcon color="action" style={{ fontSize: 40 }} />
              <Typography variant="button" display="block" gutterBottom>
                {mode === "add" ? "Select PDF" : "Change Pdf"}
              </Typography>
            </Box>
          </label>
          {fileName && (
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ mt: 1, textAlign: "center" }}
            >
              {fileName}
            </Typography>
          )}

          <LoadingButton
            loading={loading}
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 3 }}
          >
            Submit
          </LoadingButton>
        </Box>
      </Box>
    </Container>
  );
}

export default AddBrochure;
