import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  List,
  Grid,
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Collapse,
  useMediaQuery,
  CardContent,
  Card,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { styled } from "@mui/material/styles";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExploreIcon from "@mui/icons-material/Explore";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { ThemeProvider } from "@mui/material/styles";
import FloorPlanIcon from "@mui/icons-material/Map";
import ServicesIcon from "@mui/icons-material/Build";
import CatalogueIcon from "@mui/icons-material/Book";
import ScheduleIcon from "@mui/icons-material/CalendarToday";
import FavoritesIcon from "@mui/icons-material/Favorite";
import ReportIcon from "@mui/icons-material/Assessment";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import GroupIcon from "@mui/icons-material/Group"; // Example icon for Exhibitors
import CategoryIcon from "@mui/icons-material/Category"; // Example icon for Products
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter"; // Example icon for B2B AI Matchmaking
import PeopleAltIcon from "@mui/icons-material/PeopleAlt"; // Example icon for Visitors
import bgBanner from "../../../assets/gifs/ExpoBG.gif";
import { ExpoTheme } from "../../../theme/theme";
import { CircularProgressbar } from "react-circular-progressbar";
import SideMenuList from "./profile/SideMenuList";
import SideMenuBar from "../../../widgets2024/SideMenuBar";

const AppBarHeader = ({ open, handleDrawerOpen }) => (
  <AppBar position="fixed">
    <Toolbar>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        sx={{ mr: 2, ...(open && { display: "none" }) }}
      >
        <MenuIcon />
      </IconButton>
      <Typography variant="h6" noWrap component="div">
        IMTEX Forming 2024
      </Typography>
      <Box sx={{ flexGrow: 1 }} />
      <IconButton color="inherit">
        <NotificationsIcon />
      </IconButton>
      <IconButton color="inherit">
        <AccountCircle />
      </IconButton>
    </Toolbar>
  </AppBar>
);
const menuItems = [
  {
    text: "Dashboard",
    link: "/dashboard",
    icon: "DashboardIcon",
  },
  {
    text: "Explore",
    link: "#",
    icon: "ExploreIcon",
    subcategories: [
      {
        text: "Exhibitors",
        link: "/explore/exhibitors",
        icon: "GroupIcon",
      },
      {
        text: "Products",
        link: "/explore/products",
        icon: "CategoryIcon",
      },
      {
        text: "B2B AI Matchmaking",
        link: "/explore/matchmaking",
        icon: "BusinessCenterIcon",
      },
      {
        text: "Visitors",
        link: "/explore/visitors",
        icon: "PeopleAltIcon",
      },
    ],
  },
  {
    text: "Floor Plan",
    link: "/floor-plan",
    icon: "MapIcon",
  },
  {
    text: "Exhibitor Services",
    link: "/services",
    icon: "ServicesIcon",
  },
  {
    text: "Exhibition Catalogue",
    link: "/catalogue",
    icon: "BookIcon",
  },
  {
    text: "My Schedule",
    link: "/my-schedule",
    icon: "ScheduleIcon",
  },
  {
    text: "Favorites",
    link: "/favorites",
    icon: "FavoriteIcon",
  },
  {
    text: "Reports",
    link: "/reports",
    icon: "AssessmentIcon",
  },
  {
    text: "Settings",
    link: "/settings",
    icon: "SettingsIcon",
  },
  {
    text: "Logout",
    link: "/logout",
    icon: "LogoutIcon",
  },
];

function ResponsiveUI() {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  return (
    <ThemeProvider theme={ExpoTheme}>
      <Box sx={{ display: "flex" }}>
        <AppBarHeader open={open} handleDrawerOpen={handleDrawerOpen} />

        <SideMenuBar open={open} setOpen={setOpen} menuItems={menuItems} />

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            transition: ExpoTheme.transitions.create("margin", {
              easing: ExpoTheme.transitions.easing.sharp,
              duration: ExpoTheme.transitions.duration.leavingScreen,
            }),
          }}
        >
          <Toolbar />
          <Box>
            <Grid container spacing={2}>
              <Grid xs={12} item md={9}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Card>
                      <CardContent
                        sx={{
                          backgroundImage: `url(${bgBanner})`,
                          backgroundSize: "cover",
                          height: 192,
                          display: "flex", // Set display to flex
                          flexDirection: "column", // Align children in a column
                          justifyContent: "center", // Center content vertically
                          backgroundRepeat: "no-repeat",
                          color: "white", // Default text color for all text in CardContent
                        }}
                      >
                        <Typography variant="h5" component="div">
                          Welcome!
                        </Typography>
                        <Typography fontSize={17} variant="body1">
                          Indian Machine Tool Manufacturers' Association
                        </Typography>
                        <Typography mt={2} variant="body2">
                          Track your profile activity, leads and deals here.
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Card>
                      <CardContent
                        sx={{
                          height: 192,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          sx={{
                            width: 90,
                            height: 90,
                            display: "block",
                            margin: "auto",
                          }}
                        >
                          <CircularProgressbar
                            strokeWidth={12}
                            value={90}
                            text={`100%`}
                          />
                        </Box>
                        <Box
                          sx={{
                            color: "#666",
                            margin: "10px 12px",
                            fontWeight: 600,
                            fontSize: "14px",
                            textAlign: "center",
                          }}
                        >
                          Complete your profile
                        </Box>
                        <Button
                          sx={{ textTransform: "none" }}
                          variant="contained"
                          size="small"
                        >
                          Update Profile
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Card>
                      <CardContent sx={{ height: 192 }}></CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Card>
                      <CardContent>
                        <Typography>Card 1</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Card>
                      <CardContent>
                        <Typography>Card 1</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Card>
                      <CardContent>
                        <Typography>Card 1</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Card>
                      <CardContent>
                        <Typography>Card 1</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={3}>
                <Card sx={{ height: 300 }}>
                  <CardContent>
                    <Typography>Card 4</Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default ResponsiveUI;
