import React, { useState } from "react";
import ExhibitorCard from "../../../../widgets/ExhibitorCard";
import { Box, Button, Grid, Pagination, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ProfileDetails from "../../exhibitor/profile/ProfileDetails";
import useMatchedExhibitors from "../hooks/useMatchedExhibitors";
import MuPb from "../../../../widgets/MuPb";
import { useNavigate } from "react-router-dom";
import useLoginProfile from "../hooks/useLoginProfile";
import { USER } from "../../../../constants/constants";
import useCommonGetInit from "../../../events/hooks/useCommonGetInit";
import SearchBar from "../../../../widgets/searchBar";
import CustomCircularP from "../../../../widgets/CustomCircularP";
const Matchmaking = () => {
  const [page, setPage] = useState(1);
  const [sq, setSq] = useState("");
  const navigate = useNavigate();
  const { data, isLoading, isError, error } = useCommonGetInit(
    `${USER}/get-matched-exhibitors?page=${page}&sq=${sq}&`
  );

  const onPageChange = (e, page) => {
    setPage(page);
  };
  const onItemSearch = (e) => {
    setSq(e.target.value);
  };
  return (
    <div>
      <CustomCircularP show={isLoading} />
      <Box>
        <Grid
          pl={1}
          pr={2}
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h5" fontWeight={"bold"}>
              Matched Exhibitors
            </Typography>
          </Grid>
          <Grid item>
            <SearchBar
              onSearch={onItemSearch}
              label="Search Exhibitors"
              size="7px"
              boxAlign="inline-flex"
              mystyle={{
                display: "inline-flex",
                backgroundColor: "white",
                width: "276px",
              }}
              cstyle={{ flex: 1 }}
            />
          </Grid>
        </Grid>
        <div style={{ color: "green", margin: "8px" }}>
          B2B Matching AI streamlines connections between visitors and
          exhibitors by employing sophisticated algorithms to analyze user
          profiles, preferences, and business requirements, ensuring effective
          and personalized interactions at events and exhibitions.
        </div>
        {data?.data.map((exh, i) => (
          <ExhibitorCard key={i} item={exh} />
        ))}
      </Box>

      {data?.data?.length === 0 && (
        <div className="items-not-found">{"No Match Found!"}</div>
      )}

      <Box mt={2} mb={2} display={"flex"} justifyContent={"center"}>
        <Pagination
          count={Math.ceil(data?.totalItems / 30)}
          page={page}
          onChange={onPageChange}
          color="primary"
        />
      </Box>
    </div>
  );
};

export default Matchmaking;
