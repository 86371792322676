import React, { useState } from "react";
import RdLoadingButton from "../../../../widgets/RdLoadingButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TeamMember from "../../../../widgets/TeamMember";
import RdMyModal from "../../../../widgets/myModal/RdMyModal";
import AddTeamMember from "../AddTeamMember";
import {
  API_ENDPOINT,
  EXHIBITOR_API,
  USER,
} from "../../../../constants/constants";
import CustomCircularP from "../../../../widgets/CustomCircularP";
import axios from "axios";
import { getExhibitorHeader, getHeader } from "../../../../utility/utility";
import SimpleYesNoDialog from "../../../../widgets/SimpleYesNoDialog";
import HeaderLabel from "../../../../widgets/HeaderLabel";
import AddBtn from "../../../../widgets/actionBtn/addBtn";
import useTeamMembers from "../hooks/useTeamMembers";
import MuPb from "../../../../widgets/MuPb";
import { Box } from "@mui/material";
import { toast } from "react-toastify";

const TeamMembers = ({ isView = false, id }) => {
  const [page, setPage] = useState(1);
  const { data, isLoading, isError, error, refetchMembers } = useTeamMembers(
    page,
    id
  );
  const [showAddModal, setShowAddModal] = useState(false);
  const [member, setMember] = useState(null);
  const [mode, setMode] = useState("add");
  const [deleting, setDeleting] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  if (isLoading) {
    return <MuPb />;
  }

  const onAddTeamMember = () => {
    setMode("add");
    setMember(null);
    setShowAddModal(true);
  };
  const onTeamAdded = () => {
    setShowAddModal(false);
    refetchMembers();
  };
  const onEditMember = (member) => {
    setMode("edit");
    setMember(member);
    setShowAddModal(true);
  };
  const onDeleteMember = (member) => {
    setMember(member);
    setShowDelete(true);
  };
  const onYesDelete = async () => {
    setShowDelete(false);
    setDeleting(true);

    try {
      const { data } = await axios.delete(
        `${API_ENDPOINT}${USER}/delete-team-member?id=${member._id}`,
        getHeader()
      );
      setDeleting(false);
      toast.success(data.message);
      onTeamAdded();
    } catch (error) {
      setDeleting(false);
      toast.error(error.message);
    }
  };
  return (
    <div
      style={{
        backgroundColor: "white",
        padding: "16px",
        borderRadius: "8px",
      }}
    >
      <CustomCircularP show={deleting} />
      <SimpleYesNoDialog
        title="Please Confirm!"
        message="Are you sure you want to delete this?"
        onYes={onYesDelete}
        show={showDelete}
        setShow={setShowDelete}
      />
      <RdMyModal
        showModal={showAddModal}
        setShowModal={setShowAddModal}
        modalC={
          <AddTeamMember
            member={member}
            mode={mode}
            onAdded={onTeamAdded}
            show={showAddModal}
            setShow={setShowAddModal}
          />
        }
      />
      <div style={{ display: "flex" }}>
        <HeaderLabel onEdit={null} showEdit={false} label={"Team Members"} />
        <div style={{ flex: 1 }}></div>
        {!isView && (
          <AddBtn onClick={onAddTeamMember} label={"Add Team Members"} />
        )}
      </div>
      {data?.data?.map((team, i) => (
        <TeamMember
          viewMode={isView}
          key={i}
          item={team}
          onEdit={onEditMember}
          onDelete={onDeleteMember}
        />
      ))}

      {data?.data?.length === 0 && (
        <Box
          sx={{
            m: 2,
            fontSize: "18px",
            color: "#888",
            fontWeight: 600,
          }}
        >
          No Team Member Found
        </Box>
      )}
    </div>
  );
};

export default TeamMembers;
