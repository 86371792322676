import React, { useState } from "react";
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  Grid,
  Container,
  Modal,
  Box,
} from "@mui/material";
import Supplier from "./reuse/Supplier";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import useExhibitorProfile from "../hooks/useExhibitorProfile";
import MuPb from "../../../../widgets/MuPb";
import Supplier2 from "./reuse/Supplier2";
import { getExhibitorHeader } from "../../../../utility/utility";
import { EXHIBITOR_API } from "../../../../constants/constants";
import axios from "axios";
import { SpaceInfo, spaceTemplate } from "./SetupSchedule";

const AdditionalServices = () => {
  const { data, isLoading, isError, error, refetchExhibitorProfile } =
    useExhibitorProfile();
  const [submitting, setSubmitting] = useState(false);
  const [supplier, setSupplier] = useState();
  const [cart, setCart] = useState([]);
  const [isCartVisible, setIsCartVisible] = useState(false);

  const addToCart = (item) => {
    const existingItemIndex = cart.findIndex(
      (cartItem) => cartItem.serialNumber === item.serialNumber
    );

    if (existingItemIndex !== -1) {
      setCart((prevCart) =>
        prevCart.map((cartItem, index) =>
          index === existingItemIndex
            ? { ...cartItem, quantity: cartItem.quantity + 1 }
            : cartItem
        )
      );
    } else {
      setCart((prevCart) => [...prevCart, { ...item, quantity: 1 }]);
    }
    toast.success("Added to cart!", { autoClose: 500 });
  };

  const removeFromCart = (indexToRemove) => {
    setCart(cart.filter((_, index) => index !== indexToRemove));
  };

  if (isLoading) {
    return <MuPb />;
  }

  const calculateTotal = () => {
    return cart.reduce((acc, item) => {
      const cost = parseFloat(item.cost) || 0;
      const quantity = parseInt(item.quantity) || 0;
      return acc + cost * quantity;
    }, 0);
  };

  const calculateGST = (total) => {
    return total * 0.18;
  };

  const handleOpen = () => {
    setIsCartVisible(true);
  };

  const handleClose = () => {
    setIsCartVisible(false);
  };
  const generateCartHtmlTemplate = (cart) => {
    // Generate HTML for each cart item
    const cartItemsHtml = cart
      .map(
        (item) => `
      <tr>
        <td><img src="${item.image}" alt="${
          item.description
        }" style="width: 50px; height: auto;"></td>
        <td>${item.description}</td>
        <td>${item.specification}</td>
        <td>${item.quantity}</td>
        <td>${item.cost}</td>
        <td>${item.quantity * item.cost}</td>
      </tr>
    `
      )
      .join("");

    const totalCost = cart.reduce(
      (acc, item) => acc + item.cost * item.quantity,
      0
    );
    const totalGST = totalCost * 0.18;

    return `
      <div>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Description</th>
              <th>Specification</th>
              <th>Quantity</th>
              <th>Cost</th>
              <th>Subtotal</th>
            </tr>
          </thead>
          <tbody>
            ${cartItemsHtml}
          </tbody>
          <tfoot>
            <tr>
              <td colspan="5">Total</td>
              <td>${totalCost.toLocaleString()}</td>
            </tr>
            <tr>
              <td colspan="5">GST (18%)</td>
              <td>${totalGST.toLocaleString()}</td>
            </tr>
            <tr>
              <td colspan="5">Grand Total</td>
              <td>${(totalCost + totalGST).toLocaleString()}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    `;
  };

  const cartHtmlTemplate = generateCartHtmlTemplate(cart);
  const onSubmit = async () => {
    const template = `<!DOCTYPE html><html lang="en"> <head> <meta charset="UTF-8"/> <meta http-equiv="X-UA-Compatible" content="IE=edge"/> <meta name="viewport" content="width=device-width, initial-scale=1.0"/> <title>OES Email Template</title> <style>.box{margin-bottom: 16px; font-size: 18px;}strong{font-weight: bold;}/* Add table styles */ table{width: 100%; border-collapse: collapse; /* Collapse borders */}th, td{border: 1px solid #ddd; /* Add border to table header and table data */ padding: 8px; /* Add padding */ text-align: left; /* Align text to the left */}th{background-color: #f2f2f2; /* Add background color to table header */}</style></head> <body> <div style="max-width: 800px; margin-left: auto; margin-right: auto"> <img src="https://apis.expoplanner.in/images/email_header_oes.png" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> <div style="max-width: 400px"> <div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>From:</strong>Company: ${
      data.companyName.value
    }, Name: ${data.contactSalutation.value}${data.contactFirstName.value}${
      data.contactLastName.value
    }, Phone: ${data.mobile.value}, Email: ${
      data.contactEmail.value
    }</p></div><div style="border: 1px solid #000; padding: 10px; margin: 10px"> <p> <strong>To:</strong>Supplier: ${
      supplier.supplier_name
    }, Address: ${supplier.address}</p><p>Phone number: ${
      supplier.phone_number
    }</p><p>E-mail: ${supplier.email_id}</p></div></div>${spaceTemplate(
      data
    )} <br />${cartHtmlTemplate}<br /><img src="https://apis.expoplanner.in/images/email_footer_oes.png" style=" display: block; margin-left: auto; margin-right: auto; width: 100%; "/> </div></body></html>`;

    const toSubmitData = {
      template,
      name: "Additional Services",
      emails: [
        data.companyEmail.value,
        supplier.email_id[0],
        "christopher@imtma.in",
        "christopher.benny1@gmail.com",
      ],
      title: "Additional Services",
    };
    try {
      setSubmitting(true);
      const { data } = await axios.post(
        EXHIBITOR_API + "submit-oes",
        toSubmitData,
        getExhibitorHeader()
      );
      setSubmitting(false);
      toast.success(data.message);
    } catch (error) {
      setSubmitting(false);
      toast.error(error.message);
    }
  };
  return (
    <Container sx={{ flexGrow: 1, padding: 3 }}>
      <Typography variant="h5" gutterBottom>
        Additional Services
      </Typography>

      <Typography variant="body1" gutterBottom>
        <p>
          The organiser has appointed the following agencies for providing
          Additional Services.
        </p>
        Amanullah Khan’s Sons (Hall 2A & 5)
        <br />
        Pavilions & Interiors India Private. Limited. (Hall 3)
        <br />
        Meroform India Pvt Ltd (Hall 4)
        <br />
        <br />
        Note: GST 18% will be applicable
      </Typography>

      <SpaceInfo data={data} />
      <Grid container spacing={3}>
        {ITEMS.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ maxWidth: 345, margin: 2 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="140"
                  image={item.image}
                  alt={item.description}
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    {item.description}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {item.specification}
                  </Typography>
                  <Typography variant="h6" color="primary">
                    ₹{item.cost.toLocaleString()}
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <LoadingButton
                  startIcon={<AddShoppingCartIcon />}
                  variant="outlined"
                  sx={{ textTransform: "none" }}
                  size="small"
                  onClick={() => addToCart(item)}
                >
                  Add to Cart
                </LoadingButton>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box mt={2} textAlign={"right"}>
        <Button
          size="small"
          variant="outlined"
          color="primary"
          onClick={handleOpen}
        >
          View Cart
        </Button>
        <Typography variant="body1" gutterBottom>
          Total Items in Cart: {cart.length}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Total Price: ₹{calculateTotal().toLocaleString()}
        </Typography>
        <Typography variant="body1" gutterBottom>
          GST (18%): ₹{calculateGST(calculateTotal()).toLocaleString()}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Grand Total: ₹
          {(calculateTotal() + calculateGST(calculateTotal())).toLocaleString()}
        </Typography>
      </Box>
      <Modal
        open={isCartVisible}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Cart
          </Typography>
          {cart.map((item, index) => (
            <Box key={index} sx={{ margin: 2 }}>
              <Typography variant="body1">
                {item.description} (Quantity: {item.quantity})
              </Typography>
              <Typography variant="body2" color="textSecondary">
                ₹{item.cost.toLocaleString()}
              </Typography>
              <Button
                size="small"
                color="error"
                onClick={() => removeFromCart(index)}
              >
                Remove
              </Button>
            </Box>
          ))}
          <Button color="primary" onClick={handleClose}>
            Close
          </Button>
        </Box>
      </Modal>
      <Supplier2
        user={data}
        id={22}
        supplier={supplier}
        setSupplier={setSupplier}
      />
      {/* Submit Button */}
      <Box mt={4} display="flex" justifyContent="center">
        <LoadingButton
          loading={submitting}
          disabled={!supplier}
          onClick={onSubmit}
          variant="contained"
          color="primary"
          type="submit"
        >
          Submit
        </LoadingButton>
      </Box>
    </Container>
  );
};

const ITEMS = [
  {
    description: "Executive Chair",
    specification: "Black",
    cost: 1500,
    image: "https://apis.expoplanner.in/images/Executive_Chair.png",
    serialNumber: 1,
  },
  {
    description: "Visitor Chair Grey",
    specification: "grey",
    cost: 700,
    image:
      "https://apis.expoplanner.in/images/Visitor_chair_grey_upholstry.png",
    serialNumber: 2,
  },
  {
    description: "Round Table (Laminated Top)",
    specification: "70 cm (dia) x 75 cm (h)",
    cost: 1200,
    image: "https://apis.expoplanner.in/images/Round_Table_(Laminated_Top).png",
    serialNumber: 3,
  },
  {
    description: "Round Table (Glass Top)",
    specification: "90 cm (dia) x 75 cm (h)",
    cost: 1500,
    image: "https://apis.expoplanner.in/images/Round_Table_(Glass_Top).png",
    serialNumber: 4,
  },
  {
    description: "Bar Stool",
    specification: "",
    cost: 1200,
    image: "https://apis.expoplanner.in/images/Bar_Stool.png",
    serialNumber: 5,
  },
  {
    description: "Glass Showcase (Big)",
    specification: "1 m x 50 cm x 2.5 m (h)",
    cost: 3500,
    image: "https://apis.expoplanner.in/images/Glass_Showcase_(Big).png",
    serialNumber: 6,
  },
  {
    description: "Glass Showcase (Small)",
    specification: "50 cm x 50 cm x 2.5 m (h)",
    cost: 3000,
    image: "https://apis.expoplanner.in/images/Glass_Showcase_(Small).png",
    serialNumber: 7,
  },
  {
    description: "Glass Counter",
    specification: "1 m x 50 cm x 1 m (h)",
    cost: 3000,
    image: "https://apis.expoplanner.in/images/Glass_Counter.png",
    serialNumber: 8,
  },
  {
    description: "Standing Discussion Table",
    specification: "70 cm (dia) x 1 m (h)",
    cost: 1500,
    image: "https://apis.expoplanner.in/images/Standing_Discussion_Table.png",
    serialNumber: 9,
  },
  {
    description: "System Counter Table",
    specification: "1 m x 50 cm x 75 cm (h)",
    cost: 1200,
    image: "https://apis.expoplanner.in/images/System_Counter_Table.png",
    serialNumber: 10,
  },
  {
    description: "Side Rack Lockable",
    specification: "1 m x 50 cm x 75 cm (h)",
    cost: 3000,
    image: "https://apis.expoplanner.in/images/Side_Rack_Lockable.png",
    serialNumber: 11,
  },
  {
    description: "Brochure Rack",
    specification: "",
    cost: 800,
    image: "https://apis.expoplanner.in/images/Brochure_Rack.png",
    serialNumber: 12,
  },
  {
    description: "Square Table",
    specification: "",
    cost: 1200,
    image: "https://apis.expoplanner.in/images/Square_Table.png",
    serialNumber: 13,
  },
  {
    description: "Lockable Door",
    specification: "",
    cost: 3500,
    image: "https://apis.expoplanner.in/images/Lockable_Door.png",
    serialNumber: 14,
  },
  {
    description: "System Panel",
    specification: "1 m x 2.5 m",
    cost: 900,
    image: "https://apis.expoplanner.in/images/System_Panel.png",
    serialNumber: 15,
  },
  {
    description: "Glass Shelf",
    specification: "30 cm x 1 m",
    cost: 500,
    image: "https://apis.expoplanner.in/images/Glass_Shelf.png",
    serialNumber: 16,
  },
  {
    description: "Wooden Shelf",
    specification: "30 cm x 1 m",
    cost: 400,
    image: "https://apis.expoplanner.in/images/Wooden_Shelf.png",
    serialNumber: 17,
  },
  {
    description: "Long Arm Halogen Light",
    specification: "150W",
    cost: 600,
    image: "https://apis.expoplanner.in/images/Long_Arm_Halogen_Light.png",
    serialNumber: 18,
  },
  {
    description: "Spot Light",
    specification: "100W",
    cost: 450,
    image: "https://apis.expoplanner.in/images/Spot_Light.png",
    serialNumber: 19,
  },
  // {
  //   description: "LED Track Light - White/Yellow",
  //   specification: "50W",
  //   cost: 1500,
  //   image:
  //     "https://apis.expoplanner.in/images/LED_Track_Light_-_White/Yellow.png",
  //   serialNumber: 20,
  // },
  // {
  //   description: "Power Socket",
  //   specification: "",
  //   cost: 400,
  //   image: "https://apis.expoplanner.in/images/Power_Socket.png",
  //   serialNumber: 21,
  // },
];

export default AdditionalServices;
