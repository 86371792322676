import React, { useState } from "react";
import VisitorCard from "../../../../widgets/VisitorCard";
import { Box, Grid, Pagination, Typography } from "@mui/material";
import NoDataFound from "../NoDataFound";
import SearchBar from "../../../../widgets/searchBar";
import useCommonGetInit from "../../../events/hooks/useCommonGetInit";
import { USER } from "../../../../constants/constants";
import CustomCircularP from "../../../../widgets/CustomCircularP";
const Matchmaking = () => {
  const [page, setPage] = useState(1);
  const [sq, setSq] = useState("");
  const { data, isLoading, isError, error } = useCommonGetInit(
    `${USER}/get-matched-visitors?page=${page}&sq=${sq}&`
  );

  const onPageChange = (e, page) => {
    setPage(page);
  };
  const onItemSearch = (e) => {
    setSq(e.target.value);
  };
  return (
    <div>
      <CustomCircularP show={isLoading} />
      <Box>
        <Grid
          pl={1}
          pr={2}
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h5" fontWeight={"bold"}>
              Matched Visitors
            </Typography>
          </Grid>
          <Grid item>
            <SearchBar
              onSearch={onItemSearch}
              label="Search Visitors"
              size="7px"
              boxAlign="inline-flex"
              mystyle={{
                display: "inline-flex",
                backgroundColor: "white",
                width: "276px",
              }}
              cstyle={{ flex: 1 }}
            />
          </Grid>
        </Grid>
        <div style={{ color: "green", margin: "12px 8px 8px 8px" }}>
          B2B Matching AI seamlessly connects exhibitors with relevant visitors,
          leveraging advanced algorithms and data analysis to optimize business
          interactions during events.
        </div>
        <div style={{ display: "inline-block", gap: "12px" }}>
          {data?.data?.map((exh, i) => (
            <VisitorCard key={i} item={exh} />
          ))}
        </div>
        {data?.data?.length === 0 && <NoDataFound />}
      </Box>
      <Box mt={2} mb={2} display={"flex"} justifyContent={"center"}>
        <Pagination
          count={Math.ceil(data?.totalItems / 30)}
          page={page}
          onChange={onPageChange}
          color="primary"
        />
      </Box>
    </div>
  );
};

export default Matchmaking;
