import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
  Typography,
  createFilterOptions,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { MuiTelInput } from "mui-tel-input";
import { ExpoTheme } from "../../../theme/theme";
import Footer from "../../footer/footer";
import { IconButton } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import SearchIcon from "@mui/icons-material/Search";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import RegHeader from "../../../visitorregt/components/headers/regHeader";
import {
  API_ENDPOINT,
  EXHIBITOR_API,
  USER,
  VISITOR_API,
} from "../../../constants/constants";
import CustomCircularP from "../../../widgets/CustomCircularP";
import ReactToPrint from "react-to-print";
import QRCode from "react-qr-code";
import ReactDOMServer from "react-dom/server";
import {
  GROUP_ONE,
  getOnsiteHeader,
  toastError,
} from "../../../utils2024/utils";
class PrintableComponent extends React.Component {
  render() {
    return (
      <Box
        sx={{
          width: "9.5cm",
          textAlign: "center",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {this.props.data.map((row, index) => {
          return (
            <div key={index} style={{ pageBreakAfter: "always" }}>
              <div style={{ paddingLeft: "8px", paddingRight: "8px" }}>
                <h2 style={{ marginTop: "7cm" }}>{row.name.toUpperCase()}</h2>
                <h2>{row.company.toUpperCase()}</h2>
              </div>
            </div>
          );
        })}
      </Box>
    );
  }
}

class PrintableRowComponent extends React.Component {
  render() {
    const { row } = this.props;
    return (
      <div
        style={{
          textAlign: "center",
          width: "9.5cm",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div style={{ paddingLeft: "8px", paddingRight: "8px" }}>
          <h2 style={{ marginTop: "7cm" }}>{row.name.toUpperCase()}</h2>
          <h2>{row.company.toUpperCase()}</h2>
        </div>
      </div>
    );
  }
}
const ForwardPrintableComponent = React.forwardRef((props, ref) => (
  <PrintableComponent ref={ref} {...props} />
));
const OrganiserBadges = () => {
  useEffect(() => {
    document.title = "ORGANISER BADGES";
  }, []);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [exhibitors, setExhibitors] = useState([]);
  const [selected, setSelected] = useState([]);
  //   const [selectedRows, setSelectedRows] = useState([]);

  const printComponentRef = useRef();

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = exhibitors.map((n) => n.name); // Assuming each team member has a unique _id
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  const handleRowSelection = (row) => {
    const selectedIndex = selected.indexOf(row.name);
    if (selectedIndex !== -1) {
      setSelected(selected.filter((name) => name !== row.name));
    } else {
      setSelected([...selected, row.name]);
    }
  };
  useEffect(() => {
    loadExhibitors();
  }, []);
  async function loadExhibitors(hallN) {
    setLoading(true);
    try {
      const response = await axios.get(
        `${API_ENDPOINT}${USER}/get-organisers`,
        getOnsiteHeader(GROUP_ONE)
      );
      setExhibitors(response.data);
    } catch (error) {
      toastError(error);
    } finally {
      setLoading(false);
    }
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const printRow = (row) => {
    const printableContent = ReactDOMServer.renderToString(
      <PrintableRowComponent row={row} />
    );
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
        <html>
        <head>
          <style>
            body { font-family: Arial, sans-serif; }
          </style>
        </head>
        <body>
          ${printableContent}
        </body>
        </html>
      `);
    printWindow.document.close();
    printWindow.focus(); // For some browsers to trigger the print dialog
    printWindow.print();
    printWindow.close();
    axios.post(EXHIBITOR_API + "save-printed-team-member", { id: row._id });
  };
  return (
    <ThemeProvider theme={ExpoTheme}>
      <Box
        sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <div style={{ display: "none" }}>
          <ForwardPrintableComponent
            ref={printComponentRef}
            data={selected.map((id) =>
              exhibitors.find((row) => row.name === id)
            )}
          />
        </div>

        {/* <RegHeader /> */}
        <CustomCircularP show={loading} />

        <Box p={3} maxWidth={1280} ml={"auto"} mr={"auto"} width={"100%"}>
          <Box display={"flex"}>
            <Box flex={1}>
              <Typography fontWeight={"bold"}>
                Selected: {selected.length}
              </Typography>
            </Box>
            <ReactToPrint
              trigger={() => (
                <Button variant="contained" startIcon={<PrintIcon />}>
                  Print All
                </Button>
              )}
              content={() => printComponentRef.current}
            />
          </Box>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <FormControlLabel
                      control={
                        <Checkbox
                          indeterminate={
                            selected.length > 0 &&
                            selected.length < exhibitors.length
                          }
                          checked={
                            exhibitors.length > 0 &&
                            selected.length === exhibitors.length
                          }
                          onChange={handleSelectAllClick}
                        />
                      }
                      label="Select All"
                    />
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Company Name
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Print</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {exhibitors.map((row, i) => {
                  const isItemSelected = isSelected(row.name);
                  return (
                    <TableRow key={i}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onChange={() => handleRowSelection(row)}
                        />
                      </TableCell>

                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.company}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          sx={{
                            textTransform: "none",
                            width: "96px",
                            bgcolor: row.print_count > 0 ? "red" : "primary",
                          }}
                          startIcon={<PrintIcon />}
                          size="small"
                          onClick={() => {
                            printRow(row);
                          }}
                        >
                          Print
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box
          sx={{
            mt: 4,
            flexGrow: 1, // This will make the box grow to fill available space
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          {/* <Footer /> */}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default OrganiserBadges;
