import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import RdDesignationsSelect from "../../../../../visitorregt/components/previewpage/RdDesignationsSelect";
import PhoneInput from "react-phone-number-input";
import { TITLES, getHeader } from "../../../../../utility/utility";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { isValidEmail } from "../../../../../visitorregt/utils/utils";
import axios from "axios";
import { VISITOR_API } from "../../../../../constants/constants";
import RdInductrySectory from "../../../../../visitorregt/components/previewpage/RdInductrySectory";
import RdDepartment from "../../../../../visitorregt/components/previewpage/RdDepartment";
import RdEventInfoSources from "../../../../../visitorregt/components/previewpage/RdEventInfoSources";
import { PROFILE_EDIT_API } from "../../ProfileDetails";

const ProfessionalInfoEdit = ({ profile, setProfile, setShow }) => {
  const [saving, setSaving] = useState(false);
  const handleInputChange = (e) => {
    setProfile((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const handleStateChange = (key, value) => {
    setProfile((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const save = () => {
    const checks = [
      {
        attr: "selectedIndustrySector",
        message: "Please Select Industry sector",
      },
      { attr: "department", message: "Please select department" },
      { attr: "selectedEventSource", message: "Please select Event source" },
    ];

    for (let check of checks) {
      const value = profile[check.attr];
      if (
        (check.validator && !check.validator(value)) ||
        value === "" ||
        value === undefined
      ) {
        toast.error(check.message);
        return;
      }
    }
    setSaving(true);
    axios
      .post(
        PROFILE_EDIT_API,
        {
          selectedIndustrySector: profile.selectedIndustrySector,
          department: profile.department,
          selectedEventSource: profile.selectedEventSource,
        },
        getHeader()
      )
      .then((res) => {
        setSaving(false);
        toast.success(res.data.message);
        setShow((prev) => !prev);
      })
      .catch((error) => {
        setSaving(false);
        if (error.response) {
          // The request was made and the server responded with a status code outside the range of 2xx
          console.error("Error Data:", error.response.data);
          console.error("Error Status:", error.response.status);
          console.error("Error Headers:", error.response.headers);
          toast.error(
            `Error: ${error.response.data.message || "Something went wrong"}`
          );
        } else if (error.request) {
          // The request was made but no response was received
          console.error("Error Request:", error.request);
          toast.error("No response from server. Please try again later.");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error", error.message);
          toast.error("An error occurred. Please try again.");
        }
      });
  };
  return (
    <div>
      <div className="visitor-form-container">
        <div className="visitor-items-h">Professional Information</div>
        <div className="visitor-items-c">
          <div className="visitor-items-first" id="industrySector">
            <RdInductrySectory
              state={profile}
              handleStateChange={handleStateChange}
            />
          </div>
          <div className="visitor-items-sec" id="department">
            <RdDepartment
              state={profile}
              handleStateChange={handleStateChange}
            />
          </div>
        </div>
        <div className="visitor-items-c">
          <div className="visitor-items-first" id="eventSources">
            <RdEventInfoSources
              state={profile}
              handleStateChange={handleStateChange}
            />
          </div>
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        <LoadingButton
          loading={saving}
          onClick={save}
          sx={{ m: 2, width: 200 }}
          variant="contained"
        >
          Save
        </LoadingButton>
      </div>
    </div>
  );
};

export default ProfessionalInfoEdit;
