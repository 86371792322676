import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import BackBtn from "../admin/widgets/BackBtn";
import { useQuery } from "../../../utility/utility";
import useCommonGetInit from "../../events/hooks/useCommonGetInit";
import { USER } from "../../../constants/constants";
import MuPb from "../../../widgets/MuPb";
import CommonItemCard from "../../../widgets/CommonItemCard";

const ProfileViewers = () => {
  const { isLoading, isError, error, data } = useCommonGetInit(
    `${USER}/my-profile-viewers?`
  );

  if (isLoading) {
    return <MuPb />;
  }

  if (isError) {
    return <div>Error: {error.message}</div>; // Display error message if there is an error
  }

  return (
    <Box mt={1} ml={2}>
      <BackBtn />
      <Typography variant="h4" component="h2" gutterBottom>
        Profile who viewed your profile
      </Typography>
      <Box ml={1}>
        <Grid container spacing={2}>
          {data.map((user) => (
            <CommonItemCard item={user?.user_info} />
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default ProfileViewers;
