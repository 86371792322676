import React, { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import CategoriesSelectFree from "../../../../../visitorregt/components/previewpage/CategoriesSelectFree";
import { toast } from "react-toastify";
import {
  API_ENDPOINT,
  EXHIBITOR_API,
  USER,
} from "../../../../../constants/constants";
import { getExhibitorHeader, getHeader } from "../../../../../utility/utility";
import CategoryAccordion from "../../../../../visitorregt/components/previewpage/CategoryAccordion";

const CategoriesEdit = ({ user, onUpdated, show, setShow }) => {
  const [saving, setSaving] = useState(false);
  const [categories, setCategories] = useState();
  useEffect(() => {
    setCategories({ selectedCategories: user.productCategories.value || {} });
  }, [show]);
  const save = async () => {
    const toUpdateObject = {
      productCategories: {
        label: user.productCategories.label,
        value: categories.selectedCategories,
      },
    };
    setSaving(true);
    try {
      const { data } = await axios.post(
        `${API_ENDPOINT}${USER}/update-user`,
        toUpdateObject,
        getHeader()
      );
      setSaving(false);
      setShow(false);
      toast.success(data.message);
      setTimeout(() => {
        document.location.reload();
      }, 4);
    } catch (error) {
      setSaving(false);
      if (error.response) {
        // The request was made and the server responded with a status code outside the range of 2xx
        console.error("Error Data:", error.response.data);
        console.error("Error Status:", error.response.status);
        console.error("Error Headers:", error.response.headers);
        toast.error(
          `Error: ${error.response.data.message || "Something went wrong"}`
        );
      } else if (error.request) {
        // The request was made but no response was received
        console.error("Error Request:", error.request);
        toast.error("No response from server. Please try again later.");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error", error.message);
        toast.error("An error occurred. Please try again.");
      }
    }
  };
  return (
    <div>
      <div className="visitor-form-container">
        <div className="visitor-items-h">Product Categories</div>
        {categories && (
          <CategoryAccordion state={categories} setState={setCategories} />
        )}
      </div>
      <div style={{ textAlign: "center" }}>
        <LoadingButton
          loading={saving}
          onClick={save}
          sx={{ m: 2, width: 200 }}
          variant="contained"
        >
          Save
        </LoadingButton>
      </div>
    </div>
  );
};

export default CategoriesEdit;
