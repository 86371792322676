import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";

const RdEventInfoSources = ({ state, handleStateChange }) => {
  const handleInputChange = (event) => {
    handleStateChange(event.target.name, event.target.value);
  };
  return (
    <FormControl fullWidth size="small">
      <InputLabel id="demo-simple-select-label">
        How did you find out about this event?*
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        name="selectedEventSource"
        value={state.selectedEventSource}
        label="How did you find out about this event?*"
        onChange={handleInputChange}
      >
        {advertisingMethods.sort().map((position) => (
          <MenuItem key={position} value={position}>
            {position}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default RdEventInfoSources;

const advertisingMethods = [
  "Invitation from the Exhibitor",
  "Invitation from the Organizer",
  "Newspaper Advt.",
  "Social Media / Online Ads",
  "Outdoor Ads",
  "Trade Publications",
];
