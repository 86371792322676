import React, { useEffect, useState } from "react";
import Matchmaking from "./Matchmaking";
import Exhibitors from "../../exhibitor/explore/Exhibitors";
import Products from "../../exhibitor/explore/Products";
const Explore = ({ page }) => {
  const itemsA = ["Exhibitors", "Products", "B2B AI Matchmaking"];
  const [activeTab, setActiveTab] = useState(itemsA[0]);

  useEffect(() => {
    if (page === "explore") {
      setActiveTab(itemsA[0]);
    } else if (page === "exhibitors") {
      setActiveTab(itemsA[0]);
    } else if (page === "products") {
      setActiveTab(itemsA[1]);
    } else if (page === "ai-matching") {
      setActiveTab(itemsA[2]);
    } else if (page === "visitors") {
      setActiveTab(itemsA[3]);
    } else {
      setActiveTab(itemsA[4]);
    }
  }, [page]);
  return (
    <div className="rd-dash-content">
      <div>
        {activeTab === itemsA[0] ? (
          <Exhibitors />
        ) : activeTab === itemsA[1] ? (
          <Products />
        ) : activeTab === itemsA[2] ? (
          <Matchmaking />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Explore;
