import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Alert, Box, Container, Grid } from "@mui/material";
import MuPb from "../../../../widgets/MuPb";
import { USER, VISITOR } from "../../../../constants/constants";
import useAdminCommonGet from "../../../events/hooks/useAdminCommonGet";
import { useQuery } from "../../../../utility/utility";
import BackBtn from "../widgets/BackBtn";
import VProfileDetails from "../../visitor/ProfileDetails";
import ProfileDetails from "../../exhibitor/profile/ProfileDetails";

const UserProfileById = () => {
  const query = useQuery();
  const id = query.get("id");
  const { data, isLoading, isError, error } = useAdminCommonGet(
    `${USER}/get-user-by-id-ad?id=${id}&`
  );
  const [user, setUser] = useState();
  if (isLoading) return <MuPb />;
  if (isError) return <Alert severity="error">Error: {error.message}</Alert>;

  return (
    <Container>
      <Box mt={3} mb={3}>
        <BackBtn />
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {data.role === VISITOR ? (
            <VProfileDetails user={data} setUser={setUser} isView={true} />
          ) : (
            <ProfileDetails user={data} setUser={setUser} isView={true} />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default UserProfileById;
