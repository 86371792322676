import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import RdDesignationsSelect from "../../../../../visitorregt/components/previewpage/RdDesignationsSelect";
import PhoneInput from "react-phone-number-input";
import { TITLES, getHeader } from "../../../../../utility/utility";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { isValidEmail } from "../../../../../visitorregt/utils/utils";
import axios from "axios";
import { VISITOR_API } from "../../../../../constants/constants";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { PROFILE_EDIT_API } from "../../ProfileDetails";
import { toastError } from "../../../../../utils2024/utils";
const SocialLinks = ({ profile, setProfile, setShow }) => {
  const [saving, setSaving] = useState(false);
  const handleInputChange = (e) => {
    setProfile((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const handleCheckboxChange = (event) => {
    handleStateChange(event.target.name, event.target.checked);
  };
  const handleStateChange = (key, value) => {
    setProfile((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const save = () => {
    setSaving(true);
    axios
      .post(
        PROFILE_EDIT_API,
        {
          fbCheck: profile.fbCheck,
          instaCheck: profile.instaCheck,
          twitterCheck: profile.twitterCheck,
          linkedInCheck: profile.linkedInCheck,
          fbLink: profile.fbLink,
          instaLink: profile.instaLink,
          twitterLink: profile.twitterLink,
          linkedInLink: profile.linkedInLink,
        },
        getHeader()
      )
      .then((res) => {
        setSaving(false);
        toast.success(res.data.message);
        setShow((prev) => !prev);
      })
      .catch((error) => {
        setSaving(false);
        toastError(error);
      });
  };
  return (
    <div>
      <div className="visitor-form-container">
        <div className="visitor-items-h">Social Links</div>
        <div
          style={{
            color: "rgba(0, 0, 0, 0.85)",
            fontSize: "14px",
            lineHeight: 1.5,
          }}
        >
          Please Add the social links
        </div>
        <div className="visitor-social-links">
          <div style={{ display: "flex" }}>
            <Checkbox
              checked={profile.fbCheck}
              onChange={handleCheckboxChange}
              name="fbCheck"
            />
            <FacebookIcon sx={{ alignSelf: "center", color: "#1877f2" }} />
          </div>
          <div style={{ display: "flex", marginLeft: "10px" }}>
            <Checkbox
              checked={profile.instaCheck}
              onChange={handleCheckboxChange}
              name="instaCheck"
            />
            <InstagramIcon sx={{ alignSelf: "center", color: "#E4405F" }} />
          </div>
          <div style={{ display: "flex", marginLeft: "10px" }}>
            <Checkbox
              checked={profile.twitterCheck}
              onChange={handleCheckboxChange}
              name="twitterCheck"
            />
            <TwitterIcon sx={{ alignSelf: "center", color: "#1DA1F2" }} />
          </div>
          <div style={{ display: "flex", marginLeft: "10px" }}>
            <Checkbox
              checked={profile.linkedInCheck}
              onChange={handleCheckboxChange}
              name="linkedInCheck"
            />
            <LinkedInIcon sx={{ alignSelf: "center", color: "#0A66C2" }} />
          </div>
        </div>
        <div className="visitor-link-container">
          <div style={{ display: profile.fbCheck ? "block" : "none" }}>
            <TextField
              size="small"
              label="Enter Facebook Link"
              fullWidth
              value={profile.fbLink}
              onChange={handleInputChange}
              name="fbLink"
            />
          </div>
          <div style={{ display: profile.instaCheck ? "block" : "none" }}>
            <TextField
              size="small"
              label="Enter Instagram Link"
              fullWidth
              value={profile.instaLink}
              onChange={handleInputChange}
              name="instaLink"
            />
          </div>
          <div style={{ display: profile.twitterCheck ? "block" : "none" }}>
            <TextField
              size="small"
              label="Enter Twitter Link"
              fullWidth
              value={profile.twitterLink}
              onChange={handleInputChange}
              name="twitterLink"
            />
          </div>
          <div style={{ display: profile.linkedInCheck ? "block" : "none" }}>
            <TextField
              size="small"
              label="Enter LinkedIn Link"
              fullWidth
              value={profile.linkedInLink}
              onChange={handleInputChange}
              name="linkedInLink"
            />
          </div>
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        <LoadingButton
          onClick={save}
          sx={{ m: 2, width: 200 }}
          variant="contained"
        >
          Save
        </LoadingButton>
      </div>
    </div>
  );
};

export default SocialLinks;
