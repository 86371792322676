import React from "react";
const TermsAndConditions = () => {
  const containerStyle = {
    maxWidth: "800px",
    padding: "20px",
    backgroundColor: "#fff",
    borderRadius: "5px",
    margin: "16px auto",
  };

  return (
    <div style={containerStyle}>
      <h2>Terms and Conditions</h2>
      <p>
        I hereby expressly give Consent to the Indian Machine Tools
        Manufacturers’ Association (“IMTMA”) as per the requirements of the
        Digital Personal Data Protection Act, 2023.
      </p>
      <p>
        I hereby permit IMTMA and its authorized representatives to collect the
        following details of my (“Personal Data”):
      </p>
      <ol>
        <li>Name</li>
        <li>E-mail Address</li>
        <li>Contact Number</li>
      </ol>
      <p>
        I agree that the Personal Data shall be used for security purposes,
        promotion of IMTMA exhibitions, training programmes, mega events, and
        all other events, exhibitions and programmes that may be organized by
        IMTMA or its affiliates.
      </p>
      <p>
        This Consent has been made by me unconditionally, out of my free will,
        not due to any undue influence by any person or party and has been read
        and fully understood by me.
      </p>
      <p>
        This Consent may be withdrawn at any point by a written communication to
        IMTMA to be addressed to{" "}
        <a href="mailto:it.blr@imtma.in">it.blr@imtma.in</a>.
      </p>
      <center>
        <p>
          <strong>© Indian Machine Tool Manufacturers' Association</strong> All
          Rights Reserved
        </p>
      </center>
    </div>
  );
};
export default TermsAndConditions;
